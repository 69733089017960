// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "http://local.pocketpassport.com", // live server
  //API_URL: "http://35.84.157.88/api",
  //API_URL: "http://localhost:8000",
  API_URL: "http://test.pocketpassport.com/be/api", // live server  
  AJAX_TIMEOUT: 300000,
  MSG_TIMEOUT: 2500,
  API_VERSION: "v1",
  AUDIO_FILE_DEFAULT_MAXSIZE_IN_KB: 5120,
  AUDIO_FILE_DEFAULT_MAXDURATION_IN_SEC: 600,
  VOCABULARY_AUDIO_FILE_DEFAULT_MAXSIZE_IN_KB: 2000,
  VOCABULARY_AUDIO_FILE_DEFAULT_MAXDURATION_IN_SEC: 60,
  QUIZ_AUDIO_FILE_DEFAULT_MAXSIZE_IN_KB: 5000,
  QUIZ_QUSTION_AUDIO_FILE_DEFAULT_MAXSIZE_IN_KB: 5000,
  QUIZ_ANSWER_AUDIO_FILE_DEFAULT_MAXSIZE_IN_KB: 2000,
  QUIZ_AUDIO_FILE_DEFAULT_MAXDURATION_IN_SEC: 600,
  IMG_FILE_DEFAULT_MAXSIZE_IN_KB: 5000,
  DOC_FILE_DEFAULT_MAXSIZE_IN_KB: 5000,
  EXCEL_FILE_DEFAULT_MAXSIZE_IN_KB: 2000,
  ERROR_REDIRECT: true,
  view_flag: false,
  //wordpress_site:"https://pocketpassport.staging-websites.com/pricing/",
  wordpress_site: "https://pocketpassport.com/pricing-2-2/",
  firebase: {
    apiKey: "AIzaSyAmcO__SE4O96HuHcmr7pzpc8Bgs4hpQR0",
    authDomain: "pocket-passport-163723.firebaseapp.com",
    databaseURL: "https://pocket-passport-163723.firebaseio.com",
    projectId: "pocket-passport-163723",
    storageBucket: "pocket-passport-163723.appspot.com",
    messagingSenderId: "101906034923",
    appId: "1:101906034923:web:adeb11c16e481233794eae",
    measurementId: "G-PFZDL3WV58",
  },
  plan_type: localStorage.getItem("plan_type"),
  ENABLE_GOOGLE_ANALYTICS: false,
  GOOGLE_ANALYTICS_TRACKING_ID: "",
  minDate: "2015-01-01",
  minYear: 2015,
  yearLimit: 10,

  /*stripePublishkey:"pk_live_51CG86tG6SCN7i8QKRGG0SO3Kdhpc2nl7OTHakRSiq3zOErBilVvmcfx9f1WEPGLiA4xa8QLvsgbWKqFYD542jfY3002UhJV53a",
  STRIPE_KEY :'pk_test_Ka6GGV1HQBvnlRYuwnhP3d4e',*/

  stripePublishkey: "pk_test_Ka6GGV1HQBvnlRYuwnhP3d4e",
  STRIPE_KEY: "pk_test_Ka6GGV1HQBvnlRYuwnhP3d4e",
  S3_BUCKET_BASE_URL:
    "https://pocketpassport-new-development.s3-us-west-2.amazonaws.com/",
  Enable_MixPanel: false,
  // siteURL:'https://staging.pocketpassport.com',// satgging
  siteURL: "https://staging.pocketpassport.com", // test server
  wordpressURL: "https://pocketpassport.staging-websites.com/",
  froala_key:
    "dKA5cB2C2F2F2E2A5zwgjanxH-7oA-16caD-11ah1D1uxueF5C4C3E3E2C2A3D6B3D3==",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
