import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { AjaxService } from 'src/app/services/ajax.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storybook-webview-detail',
  templateUrl: './storybook-webview-detail.component.html',
  styleUrls: ['./storybook-webview-detail.component.scss']
})
export class StorybookWebviewDetailComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, public route: ActivatedRoute, private ajaxService: AjaxService, private _admin_service: AdminService,private metaTagService: Meta) { }

  config: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 5,
    mousewheel: false,
    observer: true,
    observeParents: true,
    freeMode: false,
    autoHeight: true,
    navigation: {
      nextEl: '',
      prevEl: '',
    },
    pagination: {
      el: '.story-detail-swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    // autoplay: {
    //   delay: 5000,
    // },
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      480: {
        spaceBetween: 0,
        slidesPerView: 1
      },
      640: {
        spaceBetween: 1,
        slidesPerView: 0
      },
      768: {
        spaceBetween: 0,
        slidesPerView: 1
      },
      991: {
        spaceBetween: 0,
        slidesPerView: 1
      },
      1200: {
        spaceBetween: 0,
        slidesPerView: 1
      }
    },
    on: {
      slideChange: function () {
        var sounds = document.getElementsByTagName('audio');
        for (var i = 0; i < sounds.length; i++) sounds[i].pause();
      },
    }
  };
  StoryBookId: any = '';
  listOfAddedPage: any = [];
  storybookdetails: any = [];

  ngOnInit() {
    this.StoryBookId = this.route.snapshot.params.id;
    this.getStorybookDetails();
  }

  // function to get whole storybook details
  getStorybookDetails() {
    // this.storybookdetails = {
    //   "title": "Against All Odds",
    //   "decoded_description": "This story is an inspirational account of a boy named Kartik who clives, breathes and dreams football. His world revolves around the game and even though he is one-armed, Kartik is really good at the game.",
    //   "public_action_timestamp": "2020-07-17T14:17:16+00:00",
    //   "cover_image": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/users\/schools\/1\/school-users\/183.png",
    //   "school_name": "MSSPL School"
    // };
    // this.listOfAddedPage = [
    //   {
    //     "description": "<p><span style=\"color: rgb(119, 119, 119); font-family: Lato, Arial, sans-serif; font-size: 17px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">This story is an inspirational account of a boy named Kartik who &ldquo;lives, breathes and dreams football.&rdquo; His world revolves around the game and even though he is one-armed, Kartik is really good at the game.<\/span><\/p>",
    //     "page_image": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/vocabularies\/1\/158288912783045.png",
    //     "page_audio": ""
    //   },
    //   {
    //     "description": "<p><span style=\"color: rgb(119, 119, 119); font-family: Lato, Arial, sans-serif; font-size: 17px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">This story is an inspirational account of a boy named Kartik who &ldquo;lives, breathes and dreams football.&rdquo; His world revolves around the game and even though he is one-armed, Kartik is really good at the game.<\/span><\/p>",
    //     "page_image": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/vocabularies\/3\/158288912841990.png",
    //     "page_audio": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/storybooks\/183\/audio\/160406026177307.mp3"
    //   },
    //   {
    //     "description": "<p><span style=\"color: rgb(119, 119, 119); font-family: Lato, Arial, sans-serif; font-size: 17px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">This story is an inspirational account of a boy named Kartik who &ldquo;lives, breathes and dreams football.&rdquo; His world revolves around the game and even though he is one-armed, Kartik is really good at the game.<\/span><\/p><p><br><\/p>",
    //     "page_image": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/vocabularies\/2\/158288912828846.png",
    //     "page_audio": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/storybooks\/183\/audio\/160406027990956.wav"
    //   },
    //   {
    //     "description": "<p><span style=\"color: rgb(119, 119, 119); font-family: Lato, Arial, sans-serif; font-size: 17px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">This story is an inspirational account of a boy named Kartik who &ldquo;lives, breathes and dreams football.&rdquo; His world revolves around the game and even though hes is one-armed, Kartik is really good at the game.<\/span><\/p><p><br><\/p>",
    //     "page_image": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/vocabularies\/5\/158288913085751.png",
    //     "page_audio": "https:\/\/pocketpassport-new-development.s3-us-west-2.amazonaws.com\/assets\/storybooks\/183\/audio\/160431653434459.wav"
    //   }
    // ];
    this.spinner.show();
    const form_data: any = new FormData();
    form_data.append('id', this.StoryBookId);

    let adminInfo = this._admin_service.GetAdminUserInfoFromLocalStorage();
    let token: any = adminInfo['admin_token'];

    this.ajaxService.ApiCallWithLocalization(form_data, '/story-web/storyDetails', 'post', token).subscribe(
      data => {
        this._admin_service.HandleSuccessCode(data);
        this.storybookdetails = data.response.raws.data.story_book_details;
        this.listOfAddedPage = data.response.raws.data.dataset;
        let public_time = new Date(this.storybookdetails.public_action_timestamp);

        this.metaTagService.updateTag(
          { property: 'og:locale', content: 'en_US' }
        );
        this.metaTagService.updateTag(
          { property: 'og:type', content: 'article' }
        );
        this.metaTagService.updateTag(
          { property: 'og:title', content: this.storybookdetails.title }
        );
        this.metaTagService.updateTag(
          { property: 'og:description', content: this.storybookdetails.decoded_description }
        );
        this.metaTagService.updateTag(
          { property: 'og:url', content: environment.siteURL+'/storybook/detail/'+this.StoryBookId }
        );
        this.metaTagService.updateTag(
          { property: 'article:modified_time', content: public_time.toISOString() }
        );
        this.metaTagService.updateTag(
          { property: 'og:image', content: this.storybookdetails.cover_image }
        );
        this.metaTagService.updateTag(
          { property: 'og:image:width', content: "200" }
        );
        this.metaTagService.updateTag(
          { property: 'og:image:height', content: "200" }
        );

        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this._admin_service.HandleErrorCode(error);
      }
    )
  }

  // function to play audio file
  PlayAudio(event: any, id: any) {
    //// console.log(id);
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) sounds[i].pause();
    event.preventDefault();
    (<any>document.getElementById(id)).play();
  }

}
