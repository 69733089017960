import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AjaxService } from "../ajax.service";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { ReplaySubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SchoolAdminService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ajaxService: AjaxService,
    public navCtrl: NgxNavigationWithDataComponent,
    private spinner: NgxSpinnerService
  ) {}

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : To Save the School_Admin user Info into Local Storage
  */
  SaveSchool_AdminUserInfoIntoLocalStorage(data: any) {
    localStorage.setItem("school_admin_token", data.token);
    localStorage.setItem(
      "school_admin_name",
      data.full_name != undefined ? data.full_name : ""
    );
    localStorage.setItem(
      "school_admin_school_name",
      data.school_name != undefined ? data.school_name : ""
    );
    localStorage.setItem(
      "school_admin_school_logo",
      data.school_logo != undefined ? data.school_logo : ""
    );
    localStorage.setItem(
      "plan_type",
      data.plan_type != undefined ? data.plan_type : "F"
    );
    localStorage.setItem(
      "is_verified",
      data.is_verified != undefined ? data.is_verified : 0
    );
    localStorage.setItem("email", data.email != undefined ? data.email : "");
    localStorage.setItem(
      "user_type",
      data.user_type != undefined &&
        (data.user_type == "Teacher" || data.user_type == "Trainer")
        ? "T"
        : "S"
    );
    localStorage.setItem("user_definition", data.user_type);
    localStorage.setItem("userTimezone", data.timezone);
    localStorage.setItem("notifications", data.new_notification_count);
    localStorage.setItem("school_id", data.raw_id);
    localStorage.setItem("isStripeOnboarded", data.isStripeOnboarded);
    localStorage.setItem("uuid", data.uuid);
  } //.End of SaveSchool_AdminUserInfoIntoLocalStorage()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : To Delete the School_Admin user Info from Local Storage
  */
  DeleteSchool_AdminUserInfoFromLocalStorage() {
    localStorage.removeItem("school_admin_token");
    localStorage.removeItem("school_admin_name");
    localStorage.removeItem("school_admin_school_name");
    localStorage.removeItem("school_admin_school_logo");
    localStorage.removeItem("plan_type");
    localStorage.removeItem("is_verified");
    localStorage.removeItem("email");
    localStorage.removeItem("user_type");
    localStorage.removeItem("user_definition");
    localStorage.removeItem("complete_setup");
    localStorage.removeItem("printVocabs");
    localStorage.removeItem("userTimezone");
    localStorage.removeItem("onboard");
    localStorage.removeItem("notifications");
    localStorage.removeItem("school_id");
    localStorage.removeItem("acl_data");
    localStorage.removeItem("isStripeOnboarded");
    localStorage.removeItem("uuid");
  } //.End of DeleteSchool_AdminUserInfoFromLocalStorage()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : Get School_Admin Info from Local Storage
  */
  GetSchool_AdminUserInfoFromLocalStorage() {
    let obj = {};
    obj["school_admin_token"] =
      localStorage.getItem("school_admin_token") != null
        ? localStorage.getItem("school_admin_token")
        : undefined;
    obj["school_admin_name"] =
      localStorage.getItem("school_admin_name") != null
        ? localStorage.getItem("school_admin_name")
        : undefined;
    obj["school_admin_school_name"] =
      localStorage.getItem("school_admin_school_name") != null
        ? localStorage.getItem("school_admin_school_name")
        : undefined;
    obj["school_admin_school_logo"] =
      localStorage.getItem("school_admin_school_logo") != null
        ? localStorage.getItem("school_admin_school_logo")
        : undefined;
    obj["plan_type"] =
      localStorage.getItem("plan_type") != null
        ? localStorage.getItem("plan_type")
        : undefined;
    obj["is_verified"] =
      localStorage.getItem("is_verified") != null
        ? localStorage.getItem("is_verified")
        : undefined;
    obj["email"] =
      localStorage.getItem("email") != null
        ? localStorage.getItem("email")
        : undefined;
    obj["user_type"] =
      localStorage.getItem("user_type") != null
        ? localStorage.getItem("user_type")
        : undefined;
    obj["user_definition"] =
      localStorage.getItem("user_definition") != null
        ? localStorage.getItem("user_definition")
        : undefined;
    return obj;
  } //.End of GetSchool_AdminUserInfoFromLocalStorage()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @param callback : for any callback
   @purpose : To Regenrate the token when token expires
  */
  ReGenerateToken(callback: any = null) {
    var param = {};

    let AdminInfo: any = this.GetSchool_AdminUserInfoFromLocalStorage();

    param["user_id"] = AdminInfo.school_admin_userid;
    param["login_type"] = "school_admin";

    this.ajaxService
      .ApiCall(param, "UserMasters/regenerateToken", "post")
      .subscribe(
        (result) => {
          localStorage.setItem(
            "school_admin_token",
            result.response.raws.data.token
          );
          if (callback != null) {
            callback();
          }
        },
        (error) => {
          if (error.status == 403) {
            this.DeleteSchool_AdminUserInfoFromLocalStorage();
            this.router.navigate(["/schools"]);
          }
        }
      );
  } //.End of ReGenerateToken()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @param callback : for any callback
   @purpose : HandleErrorCode, Check error code, If it is called in all over the ajaxService function to handle error with statuscode
  */
  HandleErrorCode(err: any, callback: any = null) {
    let StatusCode: number = err.status;
    let ERROR_REDIRECT = environment.ERROR_REDIRECT;
    if (err.status == 401) {
      StatusCode = 401;
      this.DeleteSchool_AdminUserInfoFromLocalStorage();
      this.spinner.hide();
      // this.router.navigate(["/schools"]);
    } else if (err.status == 404 && ERROR_REDIRECT == true) {
      this.spinner.hide();
      this.router.navigate(["/page-not-found"]);
    } else if (err.status == 500 && ERROR_REDIRECT == true) {
      this.spinner.hide();
      this.router.navigate(["/internal-server-error"]);
    }
    //return StatusCode;
  } //.End of HandleError()

  /**
   @Date : 19-11-2019
   @Developer : DeepJyoty
   @param callback : for any callback
   @purpose : Handle 202 Success statuscode
  */
  HandleSuccessCode(result: any, callback: any = null) {
    let StatusCode: number = result.status;
    if (result.status == 202) {
      StatusCode = 202;
      localStorage.setItem(
        "school_admin_token",
        result.response.raws.data.token
      );
    }
    //return StatusCode;
  } //.End of HandleSuccessCode()

  public isMobileResponsive = new ReplaySubject(1);

  setOnResponsive(ifMobileView: boolean) {
    this.isMobileResponsive.next(ifMobileView);
  }

  public getOnResponsive(): Observable<any> {
    return this.isMobileResponsive.asObservable();
  }
}
