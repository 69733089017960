import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA,ErrorHandler } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// /** External Plugin Modules for language Trasnlation */
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

/** All Components and Routing */
import { AppComponent } from './app.component';
import { AppRoutingModule} from './app-routing.module';
import { GlobalErrorHandler } from './global-error-handler';
import { DirtyFormGuard } from './guards/dirty-form.guard';
import { StorybookWebviewComponent } from './storybook-webview/storybook-webview.component';
import { StorybookWebviewDetailComponent } from './storybook-webview-detail/storybook-webview-detail.component';
import { StudentquizComponent } from './studentquiz/studentquiz.component';
import { StudentcheckoutComponent } from './studentcheckout/studentcheckout.component';
import { TeachercheckoutComponent } from './teachercheckout/teachercheckout.component';
import { CheckoutRecommendationsComponent } from './checkout-recommendations/checkout-recommendations.component';




@NgModule({
  declarations: [
    AppComponent,
    StorybookWebviewComponent,
    StorybookWebviewDetailComponent,
    StudentquizComponent,
    StudentcheckoutComponent,
    TeachercheckoutComponent,
    CheckoutRecommendationsComponent    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA ],
  exports: [SharedModule],
  providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler},DirtyFormGuard]
  // providers: [DirtyFormGuard]
})
export class AppModule { }
 

/* required for AOT compilation for Translation Service  */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
}