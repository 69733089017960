import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortname'
})
export class ShortnamePipe implements PipeTransform {

  // transform(value: any, ...args: any[]): any {
  //   return null;
  // }


  transform(value: string): string {

    if(value==undefined || value.length<=0 ) return "";
    else{
      var arrName = value.split(' ');  
      var Frstltr = (arrName[0]==undefined || arrName[0]=="undefined")?"": arrName[0].charAt(0).toUpperCase();  
      var Lastltr = (arrName[arrName.length-1]==undefined || arrName[arrName.length-1]=="undefined")?"": 
      arrName[arrName.length-1].charAt(0).toUpperCase();   
      return (Frstltr+Lastltr);
    }
  }

}
