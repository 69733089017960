import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-admin-success-error-msg',
  templateUrl: './admin-success-error-msg.component.html',
  styleUrls: ['./admin-success-error-msg.component.scss']
})
export class AdminSuccessErrorMsgComponent implements OnInit {

  @Input() successMessage: string;
  @Input() errorMessage: string;
  
  constructor() { }

  ngOnInit() {
  }

  CloseSuccessErrorMsg(event : any){
    event.preventDefault();
    this.successMessage = "";
    this.errorMessage = "";
  }

}
