import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout-recommendations',
  templateUrl: './checkout-recommendations.component.html',
  styleUrls: ['./checkout-recommendations.component.scss']
})
export class CheckoutRecommendationsComponent implements OnInit {

  @Input() plan_id;
  @Input() usertype;
  @Output() planIdChanged = new EventEmitter<string>();
  constructor(private router: Router, private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params
    .subscribe((value) => {
      if(value && value!={} && value.plan_id) {
        this.plan_id = value.plan_id.toUpperCase();
        this.planIdChanged.emit(this.plan_id);
      }
    });
  }

  navigateTo(path){
     // this.router.navigateByUrl(path);
      window.open(path,"_blank");
  }

}
