import { Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appCharecterLimit]'
})
export class CharecterLimitDirective {

  constructor(private el:ElementRef) { }
  @Input('appCharecterLimit') appCharecterLimit: number;

  @HostListener('keypress', ['$event']) onChange(event:any){

    let e = <KeyboardEvent> event;
    e = e || event;
    if(this.el.nativeElement.value.length < this.appCharecterLimit){
        return true;
    } else {
         //e.preventDefault(); 
        return false;
    } 
  }


  @HostListener('paste',['$event']) onEvent(event) {
    let e = <KeyboardEvent> event;
    e = e || event;
    setTimeout(()=>{
      let str =  this.el.nativeElement.value.substring(0,this.appCharecterLimit) ;
      this.el.nativeElement.value =  str ;
    },5);
  }





}
