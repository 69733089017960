import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';
import moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {
  planType:any='Free';//Teacher,Educator
  lang_array : any = ['en', 'jp'];
  froala_key: any = environment.froala_key;

  //emailRegx1 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-]+\.)+[a-zA-Z]{2,}))$/;   
  //youtubeurlRegex = /^https:\/\/(?:www\.)?youtube.com\/watch\?(?=.*v=\w+)(?:\S+)?$/ ;
  youtubeurlRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  full_name_regex :any = /^[a-zA-Z\.\'\-]{2,50}(?: [a-zA-Z\.\'\-]{2,50})+$/;
  minuteRegx = /^[0-9]*$/;

  constructor(private _translate: TranslateService, private router: Router,public http: HttpClient) { }


 /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : Save Language into Local Storage
  */
 SaveLanguage(lang : any ){
   localStorage.setItem("lang",lang);
   this._translate.setDefaultLang(lang);
 }


 /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : get Language from Local Storage
  */
 GetLanguage(){
  let lang = (localStorage.getItem("lang")!= null 
              && localStorage.getItem("lang")!= undefined
              && localStorage.getItem("lang")!= ""
              ) ? localStorage.getItem("lang") : "en" ;
  return lang;
 }




 /** ++++++++++++++++++++++ Flaora Editor Options  +++++++++++++++++++*/

 froalaOptions: Object = {
  key: environment.froala_key,
  heightMax: 150,
  toolbarButtons: [
    'bold', 'italic', 'underline', 'subscript', 'superscript', 'clearFormatting', 'insertLink', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'formatOL', 'formatUL'
  ],
  imagePasteProcess:false,imagePaste: false,imageUpload: false,
  events: {
    // 'image.beforeUpload': function (files) {
      
    //   var editor = this;
    //   if (files.length) {
    //      // Create a File Reader.
    //      var reader = new FileReader();
    //      // Set the reader to insert images when they are loaded.
    //      reader.onload = (e:any)=>{
    //        var result = e.target.result;
    //        editor.image.insert(result, null, null, editor.image.get());
    //      };
    //      // Read image as base64.
    //      reader.readAsDataURL(files[0]);         
    //   }
    //   editor.popups.hideAll();
    //   // Stop default upload chain.
    //   return false;
    // },
    'contentChanged': () => {
      // Nothing
      //console.log('contentChanged', this.model.details);
    }
  },
};//froalaOptions End

 /** ++++++++++++++++++++++ Flaora Editor Options End +++++++++++++++++++*/




/**
   @Date : 20-11-2019
   @Developer : DeepJyoty
   @Purpose : To check the Img file is valid or not
   @Description : It only supports mp3 format
   @Param : file -> event.target.files[i]
   @Param : IsFileSizeCheck (boolean) Optional -> commonHelperService.CheckImgFileIsValid($event,true,450)
   @Param : file maximum size limit InKB Optional 
   @Output : When IsFileSizeCheck is not given , then it returns boolean [true] / [false], If given it returns [false,"limitcross"] or [true,"ok"]
  */
 CheckImgFileIsValidFromMultiImgUpload(file : any, IsFileSizeCheck : boolean = false, filesizeInKB = environment.IMG_FILE_DEFAULT_MAXSIZE_IN_KB) {
  
        //const file = event.target.files[0];
        const fileSize = file.size ;
        let filename : any = file.name;
        let extension : any = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
        //console.log("extension : ",extension);
        if(extension=="jpg" || extension =="png" || extension=="jpeg"){ 
          if(IsFileSizeCheck){
            // Check For file size
            if(fileSize!=undefined && fileSize <= (filesizeInKB*1000)){ return [true,"ok"]; }
            else { return [false,"limitcross"]; }
          }
          else{
            return [true]; 
          }
        }
        else{ return [false]; }
  
 
}//.End of CheckImgFileIsValid()



/**
   @Date : 20-11-2019
   @Developer : DeepJyoty
   @Purpose : To check the Img file is valid or not
   @Description : It only supports mp3 format
   @Param : event  -> FileOnChangeEvent($event) -> commonHelperService.CheckImgFileIsValid($event)
   @Param : IsFileSizeCheck (boolean) Optional -> commonHelperService.CheckImgFileIsValid($event,true,450)
   @Param : file maximum size limit InKB Optional 
   @Output : When IsFileSizeCheck is not given , then it returns boolean [true] / [false], If given it returns [false,"limitcross"] or [true,"ok"]
  */
 CheckImgFileIsValid(event : any, IsFileSizeCheck : boolean = false, filesizeInKB = environment.IMG_FILE_DEFAULT_MAXSIZE_IN_KB) {
    if (event.target.files.length > 0) {
          const file = event.target.files[0];
          const fileSize = file.size ;
          let filename : any = file.name;
          let extension : any = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
          //console.log("extension : ",extension);
          if(extension=="jpg" || extension =="png" || extension=="jpeg"){ 
            if(IsFileSizeCheck){
              // Check For file size
              if(fileSize!=undefined && fileSize <= (filesizeInKB*1000)){ return [true,"ok"]; }
              else { return [false,"limitcross"]; }
            }
            else{
              return [true]; 
            }
          }
          else{ return [false]; }
    }//.End of if (event.target.files.length > 0) 
    else return [false];
  }//.End of CheckImgFileIsValid()
  



 /**
   @Date : 20-11-2019
   @Developer : DeepJyoty
   @Purpose : To check the Audio file is valid or not
   @Description : It only supports mp3 format
   @Param : event  -> FileOnChangeEvent($event) -> commonHelperService.CheckAudioFileIsValid($event)
   @Param : IsFileSizeCheck (boolean) Optional -> commonHelperService.CheckAudioFileIsValid($event,true,450)
   @Param : file maximum size limit InKB Optional 
   @Param : audio Maximum Duration in Sec Optional
   @Output : When IsFileSizeCheck is not given , then it returns boolean [true] / [false], If given it returns [false,"limitcross"] or [true,"ok"]
  */
 async CheckAudioFileIsValid(event : any, IsFileSizeCheck : boolean = false, filesizeInKB = environment.AUDIO_FILE_DEFAULT_MAXSIZE_IN_KB, audioMaxDuration = environment.AUDIO_FILE_DEFAULT_MAXDURATION_IN_SEC) {
  if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const fileSize = file.size ;
        let filename : any = file.name;
        let extension : any = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
        //console.log("extension : ",extension);
        if(extension=="mp3"){ 
          if(IsFileSizeCheck){
            // Check For file size
            if(fileSize!=undefined && fileSize <= (filesizeInKB*1000)){ 
               let duration : any = await this.getDuration(file);
               if(duration<=audioMaxDuration){
                  return [true,"ok"]; 
               }
               else{
                return [false,"durationcross"];
               }
            }
            else { return [false,"limitcross"]; }
          }
          else{
            return [true]; 
          }
        }
        else{ return [false]; }
  }//.End of if (event.target.files.length > 0) 
  else return [false];
}//.End of CheckImgFileIsValid()




getDuration(file : any) {
  return new Promise(function(resolve) {
        var audio = new Audio();     
        audio.addEventListener("loadedmetadata", function() {
          console.log("audio.duration11 ",audio.duration);
          resolve(audio.duration)
        })
        var objectUrl = URL.createObjectURL(file);
        audio.src = objectUrl;
  });
}





 /**
   @Date : 20-11-2019
   @Developer : DeepJyoty
   @purpose : To check the Audio file is valid or not
   @Description : It only supports pdf,doc,docx format
   @Param : event  -> FileOnChangeEvent($event) -> commonHelperService.CheckDocumentFileIsValid($event)
   @Param : IsFileSizeCheck (boolean) Optional -> commonHelperService.CheckDocumentFileIsValid($event,true,450)
   @Param : file maximum size limit InKB Optional 
   @Output : When IsFileSizeCheck is not given , then it returns boolean [true] / [false], If given it returns [false,"limitcross"] or [true,"ok"]
  */

 CheckDocumentFileIsValid(event : any, IsFileSizeCheck : boolean = false, filesizeInKB = environment.DOC_FILE_DEFAULT_MAXSIZE_IN_KB) {
  if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const fileSize = file.size ;
        let filename : any = file.name;
        let extension : any = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
        //console.log("extension : ",extension);
        if(extension=="pdf" || extension=="doc" || extension=="docx"){ 
          if(IsFileSizeCheck){
            // Check For file size
            if(fileSize!=undefined && fileSize <= (filesizeInKB*1000)){ return [true,"ok"]; }
            else { return [false,"limitcross"]; }
          }
          else{
            return [true]; 
          }
        }
        else{ return [false]; }
  }//.End of if (event.target.files.length > 0) 
  else return [false];
}//.End of CheckImgFileIsValid()


CheckPptFileIsValid(event : any, IsFileSizeCheck : boolean = false, filesizeInKB = environment.DOC_FILE_DEFAULT_MAXSIZE_IN_KB) {
  if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const fileSize = file.size ;
        let filename : any = file.name;
        let extension : any = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
        //console.log("extension : ",extension);
        if((extension=="ppt") || (extension=="pptx")){ 
          if(IsFileSizeCheck){
            // Check For file size
            if(fileSize!=undefined && fileSize <= (filesizeInKB*1000)){ return [true,"ok"]; }
            else { return [false,"limitcross"]; }
          }
          else{
            return [true]; 
          }
        }
        else{ return [false]; }
  }//.End of if (event.target.files.length > 0) 
  else return [false];
}//.End of CheckImgFileIsValid()



/**
   @Date : 20-11-2019
   @Developer : DeepJyoty
   @purpose : To check the Excel file is valid or not
   @Description : It only supports xlx,xlsx format
   @Param : event  -> FileOnChangeEvent($event) -> commonHelperService.CheckExcelFileIsValid($event)
   @Param : IsFileSizeCheck (boolean) Optional -> commonHelperService.CheckExcelFileIsValid($event,true,450)
   @Param : file maximum size limit InKB Optional 
   @Output : When IsFileSizeCheck is not given , then it returns boolean [true] / [false], If given it returns [false,"limitcross"] or [true,"ok"]
  */

 CheckExcelFileIsValid(event : any, IsFileSizeCheck : boolean = false, filesizeInKB =  environment.EXCEL_FILE_DEFAULT_MAXSIZE_IN_KB) {
    if (event.target.files.length > 0) {
          const file = event.target.files[0];
          const fileSize = file.size ;
          let filename : any = file.name;
          let extension : any = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
          //console.log("extension : ",extension);
          if(extension=="xls" || extension=="xlsx"){ 
            if(IsFileSizeCheck){
              // Check For file size
              if(fileSize!=undefined && fileSize <= (filesizeInKB*1000)){ return [true,"ok"]; }
              else { return [false,"limitcross"]; }
            }
            else{
              return [true]; 
            }
          }
          else{ return [false]; }
    }//.End of if (event.target.files.length > 0) 
    else return [false];
  }//.End of CheckImgFileIsValid()


 /**
   @Date : 21-11-2019
   @Developer : DeepJyoty
   @purpose : To get a GUID
   @Output : Guid String
  */
GetGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}//.End of GetGuid()




 /**
   @Date : 21-11-2019
   @Developer : DeepJyoty
   @purpose : To make a long string in eclipse ... mode
   @Output : loreem ipsum ...
  */
 MakeShortStringWithEclipse(str : string ,maximumlength : number) {
   if(str==undefined) return "";
   if(str.length <= maximumlength ) return str;
   else{
     return (str.substr(0,maximumlength-3)+"..." );
   }

}//.End of MakeShortStringWithEclipse()



 /**
   @Date : 21-11-2019
   @Developer : DeepJyoty
   @purpose : To get the youtube Id from youtube url
   @Output : <youtube_ID>
  */
Get_youtube_ID(url : any){
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	var match = url.match(regExp);
	if (match&&match[7].length==11){
	    var b=match[7];
	    return(b);
	}else{
	    return("incorrect");
	}
}





/**
* Date: 29-08-2019
* Developer: Deepjyoty Roy
* [ Download the file ]
*/
SaveFile(filepath : any, mode: string = 'excel') {

    let filename : string = filepath.substring(filepath.lastIndexOf('/')+1);
   

    // var blob = new Blob([dataset.file_data], { type: 'text/csv' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { 
        // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx 
       
       
        var byteCharacters = atob(filename);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob ;
        if(mode=='excel'){
            blob= new Blob([byteArray], {type: 'application/vnd.ms-excel;'});
        }else if(mode=='pdf'){
            blob= new Blob([byteArray], {type: 'application/pdf;'});
        }else{
            blob= new Blob([byteArray], {type: 'application/vnd.ms-excel;'});
        }
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
    
        var a = window.document.createElement("a");
        if(mode=='excel'){
           
            a.href = filepath;
            
        }else if(mode=='pdf'){
            a.href = filepath;
        }else{
            a.href = filepath;
        }
       // a.download = filename ;
        document.body.appendChild(a);
        a.target = "_blank";
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        setTimeout(()=>{ document.body.removeChild(a); },1000);     
    }
}//.End of SaveFile()



/**
* Date: 29-08-2019
* Developer: Deepjyoty Roy
* [ Convert to base64 data into ArrayBuffer for Chrome download the large excel file ]
*/
s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

   



ConvertStringToImg(str : string,canvas_width : number,canvas_height: number,font_size:number,
  color_hex_code:string,canvas_x:number,canvas_y:number,font_weight:number=500){

 // console.log('List', str);

    var canvas = document.createElement('canvas');
    canvas.width = canvas_width;
    canvas.height = canvas_height;

    var tCtx = canvas.getContext('2d');
    tCtx.font = font_weight+" "+font_size+"px Montserrat";
    tCtx.fillStyle = color_hex_code;
    tCtx.fillText(str,canvas_x, canvas_y);
    let canvus_url = ""+tCtx.canvas.toDataURL();
    canvas.remove();
    //(<any>document.getElementById('image')).src = tCtx.canvas.toDataURL();
    return (canvus_url);
}

/** ++++++++++++++++++++++ Flaora Editor Options  +++++++++++++++++++*/

 imgOptions: Object = {
    angularIgnoreAttrs: ['style', 'ng-reflect-froala-editor', 'ng-reflect-froala-model'],
    immediateAngularModelUpdate: true, 
    events: {
      "contentChanged": () => {}
    }
  }


 /** ++++++++++++++++++++++ Flaora Editor Options End +++++++++++++++++++*/


 /**
    @Date : 18-11-2019
    @CreatedBy : Deepjyoty 
    @purpose :To Play the audio in Vocabulary List
 */
PlayOnlyThatAudio(data : any,ListVocabulary : [any]){
  let audioid = data.elementid;
  let raw_id = data.raw_id;

  ListVocabulary.forEach((voc:any) => {
    voc.audioIsPlaying = false;
  });
  var audios = document.getElementsByTagName('audio');
  for(var i = 0, len = audios.length; i < len;i++){
       audios[i].pause();
  }
  ListVocabulary.forEach((voc:any) => {
    if(voc.raw_id == raw_id){
        setTimeout(()=>{ 
          voc.audioIsPlaying = true;
        },10);
      (<any>document.getElementById(audioid)).currentTime = 0;
      (<any>document.getElementById(audioid)).play();
    }   
  });

}




  selectedLang = new BehaviorSubject<any>('');

  getLanguageDropdown(): Observable<any> { 
    return this.selectedLang.asObservable(); 
  }
  setLanguage(lang: any) { 
    this.selectedLang.next(lang); 
  } 
  checkPermission(){
    if(localStorage.getItem('plan_type')=='T')
    {
      this.router.navigate(['/upgrade']);
    }
  }
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  currentMinTime(){
    return  new Date(new Date().toLocaleString('en-US', {timeZone: localStorage.getItem('userTimezone')}));
  }

  calendarDefaultTime(){
    var format = 'YYYY-MM-DD';
    var formated_time =  moment(new Date(), format).tz(localStorage.getItem('userTimezone')).format(format);
    return formated_time;
  }

  getTitle(id){
    return this.http.get("https://noembed.com/embed?url=https://www.youtube.com/watch?v=" + id).toPromise(); 
  }


  checkIOSDevice() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
}
