import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-img-crop-popup',
  templateUrl: './img-crop-popup.component.html',
  styleUrls: ['./img-crop-popup.component.scss']
})
export class ImgCropPopupComponent implements OnInit {

  @Input() imageChangedEvent: any = null; 
  @Input() maintainAspectRatio: any = true; 
  @Input() aspectRatio: any = 1 / 1; 
  @Input() containWithinAspectRatio: any = true; 
  @Input() resizeToWidth: any = 200 ;
  @Input() resizeToHeight: any = 200; 
  @Input() backgroundColor: any = 'transparent' ; 
  @Input() cropperMinWidth : any = 200;
  @Input() cropperMinHeight : any = 200;
  @Input() onlyScaleDown: any = true;
  @Input() format: any = 'png';
  @Input() transform: any = { scale: 1, rotate: 0, flipH: false, flipV: false };
  croppedImage: any = '';

  @Output() imgCroppedDone: EventEmitter<number> =   new EventEmitter();
  @Output() imgCroppedCancel: EventEmitter<number> =   new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event)
  }

  SetImage(){
    this.imgCroppedDone.emit(this.croppedImage);
  }

  CroppedCancel(){
    this.imgCroppedCancel.emit();
  }

}