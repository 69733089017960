import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonHelperService } from "../../../services/common-helper.service";

@Component({
  selector: 'app-vocabulary-card-view',
  templateUrl: './vocabulary-card-view.component.html',
  styleUrls: ['./vocabulary-card-view.component.scss']
})
export class VocabularyCardViewComponent implements OnInit {

  @Input() id: string;
  @Input() raw_id: string;
  @Input() imglink: string;
  @Input() audiolink: string;
  @Input() word_title: string;
  @Input() part_of_speech: string;
  @Input() definition: string;
  @Input() ListOfKeyword: any = [];
  @Input() IsCheckboxShow: boolean = true;
  @Input() IsChecked: boolean = false;
  @Input() IsStoryboard: number;
  @Input() audioIsPlaying: boolean;

  @Output() OnSelectChange: EventEmitter<any> = new EventEmitter();
  @Output() OnPlay: EventEmitter<any> = new EventEmitter();

  isChecked: boolean = false;

  ListOfKeywordShow: any = [];
  KeywordMoreCount: any = 0;

  GuidID: any = "";
  public selectedLangDrop: any = "";
  
  constructor(
    public commonHelperService: CommonHelperService,
  ) { }

  ngOnInit() {
    this.GuidID = this.commonHelperService.GetGuid();
    this.isChecked = this.IsChecked;
    if (this.definition != "" && this.definition != null) {



      let Def_Obj: any = JSON.parse(this.definition);
      this.commonHelperService.getLanguageDropdown().subscribe(
        response => {
          this.selectedLangDrop = response;
          if (this.selectedLangDrop == "") {
            let lang = this.commonHelperService.GetLanguage();
            let definition_txt = lang == "en" ? Def_Obj.en : Def_Obj.jp;
            //this.definition = this.commonHelperService.MakeShortStringWithEclipse(Def_Obj.en,40);
            this.definition = this.commonHelperService.MakeShortStringWithEclipse(
              definition_txt,
              40
            );
          } else {
            let lang = this.selectedLangDrop;
            let definition_txt = lang == "en" ? Def_Obj.en : Def_Obj.jp;
            //this.definition = this.commonHelperService.MakeShortStringWithEclipse(Def_Obj.en,40);
            this.definition = this.commonHelperService.MakeShortStringWithEclipse(
              definition_txt,
              40
            );
          }
        },
        error => {

        }
      );

      

      
    }
    if (this.ListOfKeyword != undefined && this.ListOfKeyword.length > 0) {
      this.ListOfKeywordShow = this.ListOfKeyword.slice(0, 2);
      if (this.ListOfKeyword.length > 2) {
        this.KeywordMoreCount = this.ListOfKeyword.length - 2;
      }
    }

    console.log(this.IsStoryboard);
  }

  ngAfterViewInit() {}

  CheckOnChange() {
    this.OnSelectChange.emit({
      id: this.id,
      raw_id: this.raw_id,
      IsSelected: this.isChecked
    });
  }

  playAudio(ev: Event, audioid: any) {
    ev.preventDefault();
    //(<any>document.getElementById(audioid)).play();

    this.OnPlay.emit({ elementid: audioid, raw_id: this.raw_id });
  }

}
