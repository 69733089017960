import {Component, OnChanges, Input, SimpleChange, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'password-strength-bar',
        styles: [`
    ul#strengthBar {
        display:inline;
        list-style:none;
        margin:0;
        padding:0;
        vertical-align:2px;
    }
    .point:last {
        margin:0 !important;
    }
    .point {
        background:#DDD;
        border-radius:2px;
        display:inline-block;
        height:5px;
        margin-right:1px;
        width:20px;
    }
    #strength small{
      color: #5d5d5d;
      margin-left: 0px;
      font-size: 12px;
      font-weight: 600;
    }
    `],
    template: `
    <div id="strength" #strength>
        <!--<small class="bar-label">{{barLabel}}</small>
        <ul id="strengthBar">
            <li class="point" [style.background-color]="bar0"></li>
            <li class="point" [style.background-color]="bar1"></li>
            <li class="point" [style.background-color]="bar2"></li>
            <li class="point" [style.background-color]="bar3"></li>
            <li class="point" [style.background-color]="bar4"></li>
        </ul>-->
        
        <small class="bar-label" *ngIf="IsAdminMode==true" [style.color]="strengthStringColor">{{strengthString}}</small>
        <small class="bar-label" *ngIf="IsAdminMode==false && strengthMode=='Weak'" [style.color]="strengthStringColor">
          {{'school-admin-section.common_words.password_weak' | translate }}
        </small>
        <small class="bar-label" *ngIf="IsAdminMode==false && strengthMode=='Medium'" [style.color]="strengthStringColor">
          {{'school-admin-section.common_words.password_medium' | translate }}
        </small>
        <small class="bar-label" *ngIf="IsAdminMode==false && strengthMode=='Strong'" [style.color]="strengthStringColor">
        {{'school-admin-section.common_words.password_strong' | translate }}
      </small>


    </div>
`
})
export class PasswordStrengthBar implements OnChanges {
    
    @Input() passwordToCheck: string = '';
    @Input() barLabel: string = '';
    @Output() valueChange = new EventEmitter();


    bar0: string;
    bar1: string;
    bar2: string;
    bar3: string;
    bar4: string;

    strengthString : string = '';
    strengthMode : string = '';
    strengthStringColor : string = '#5d5d5d';

    private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];


    constructor( private translate :TranslateService) {   
    }
  

    private static measureStrength(pass: string) {
        let score = 0;
        // award every unique letter until 5 repetitions
        let letters = {};
        for (let i = 0; i< pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        let variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
        };

        let variationCount = 0;
        for (let check in variations) {
        variationCount += (variations[check]) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;
        return Math.trunc(score);
    }

  private getColor(score: number) {
    let idx = 0;
    if (score > 90) {
      idx = 4;
    } else if (score > 70) {
      idx = 3;
    } else if (score >= 40) {
      idx = 2;
    } else if (score >= 20) {
      idx = 1;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

    ngOnChanges(changes: {[propName: string]: SimpleChange}): void {

        var password = changes['passwordToCheck'].currentValue;
        this.setBarColors(5, '#DDD');
        if (password) {
            let c = this.getColor(PasswordStrengthBar.measureStrength(password));
            this.setBarColors(c.idx, c.col);
            this.set_strengthString(c.idx);
        }
        else{
          this.strengthString = '';
          this.strengthMode = '';
        }
        if(this.strengthMode == "Weak"){ this.strengthStringColor = '#F00'; this.valueChange.emit("#F00"); }
        else if(this.strengthMode == "Medium"){ this.strengthStringColor = '#F90'; this.valueChange.emit("#F90"); }
        else if(this.strengthMode == "Strong"){ this.strengthStringColor = '#86b73c'; this.valueChange.emit("#86b73c"); }
        else if(this.strengthMode == ""){ this.strengthStringColor = '#5d5d5d'; this.valueChange.emit("#ddd"); } 
    }

    
    private setBarColors(count, col) {
        for (let _n = 0; _n < count; _n++) {
            this['bar' + _n] = col;
        }
    }

    IsAdminMode : boolean = false;



    private set_strengthString(idx_param) {
     let admin_token : any =  localStorage.getItem('admin_token');
    
     if((admin_token==undefined || admin_token==null || admin_token=='')){ this.IsAdminMode = false;}
     else{  this.IsAdminMode = true; }

      if(idx_param == 1){ 
        this.strengthMode = "Weak"; 

        this.strengthString = (!(admin_token==undefined || admin_token==null || admin_token==''))?"Weak": this.translate.instant('school-admin-section.common_words.password_weak' ); }
     
      else if(idx_param == 2){ 
        this.strengthMode = "Weak"; 
        this.strengthString =  (!(admin_token==undefined || admin_token==null || admin_token==''))?"Weak":this.translate.instant('school-admin-section.common_words.password_weak' ); 
      }
      else if(idx_param == 3){ 
        this.strengthMode = "Medium"; 
        this.strengthString =  (!(admin_token==undefined || admin_token==null || admin_token==''))?"Medium": this.translate.instant('school-admin-section.common_words.password_medium' ); 
      }
      else if(idx_param == 4){ 
        this.strengthMode = "Strong"; 
        this.strengthString =  (!(admin_token==undefined || admin_token==null || admin_token==''))?"Strong":this.translate.instant('school-admin-section.common_words.password_strong' ); 
      }
      else if(idx_param == 5){ 
        this.strengthMode = "Strong"; 
        this.strengthString =  (!(admin_token==undefined || admin_token==null || admin_token==''))?"Strong":this.translate.instant('school-admin-section.common_words.password_strong' );  
      }
      else { 
        this.strengthString = "" ; 
        this.strengthMode = '';
       }
    }

}
