import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { CommonHelperService } from '../app/services/common-helper.service';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment'; // Google Analytics

declare let gtag: Function; // Google Analytics

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  
  constructor(private _translate: TranslateService, private _commonHelperService : CommonHelperService,
    private router: Router,private titleService: Title, private http: HttpClient,private messagingService : MessagingService) {
        let lang : any = _commonHelperService.GetLanguage();
        _translate.addLangs(_commonHelperService.lang_array);
        _translate.setDefaultLang(lang);
         // Begin: Google Analytics
        if (environment.ENABLE_GOOGLE_ANALYTICS) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                  gtag('config', environment.GOOGLE_ANALYTICS_TRACKING_ID,
                    {
                      'page_path': event.urlAfterRedirects
                    }
                  );
                }
              }
            )
        }
        // Begin: Google Analytics 
      }//.End of constructor()



  /**
   * [ngOnInit app component on init function , It is the section for global]
   */
  ngOnInit(){
    this.messagingService.requestPermission();
    let lang : any = this._commonHelperService.GetLanguage();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        
            this.http.get("./assets/json/titlemeta.json").subscribe(
            (data : any) => {
                let currentRoute = event.url;
                let IsFoundUrl : any = false;
                data.ListTitleMeta.forEach(element => {  
                    if(element.IsExactMatch==true){
                      if(currentRoute.toLowerCase() == element.url.toLowerCase()){
                            if(lang == 'jp') {
                                this.titleService.setTitle(element.title_jp);  
                            } else {
                                this.titleService.setTitle(element.title_en);
                            }
                            IsFoundUrl = true;
                            return false;
                      }
                    }
                    else{
                          if(currentRoute.toLowerCase().startsWith(element.url.toLowerCase())){
                            //this.titleService.setTitle(element.title_en);
                            if(lang == 'jp') {
                                this.titleService.setTitle(element.title_jp);  
                            } else {
                                this.titleService.setTitle(element.title_en);
                            }
                            IsFoundUrl = true;
                            return false;
                          }
                    }
                });
                if(!IsFoundUrl){
                  let currentRouteUrl : any = currentRoute.toLowerCase() ;
                  if(currentRouteUrl.startsWith("/schools")){
                    setTimeout(()=>{
                        this.titleService.setTitle( this._translate.instant('basic_title' ) );
                  
                    },10);
                    
                    }else{
                    this.titleService.setTitle("Pocket Passport");
                  }
                }
            }); //.End of http.get().subscribe
        }
        /** Set the current position at the top of the window */
        setTimeout(()=>{
          window.scrollTo(0,0);
        },20);
         
      });//.End of router.events.subscribe
    }//.End of ngOnInit()

    

}
