import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { CommonHelperService } from '../services/common-helper.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { AjaxService } from '../services/ajax.service';
import { StudentService } from '../services/student/student.service';
import moment from 'moment-timezone';
import { MixpanelService } from '../services/mixpanel.service';
import { HttpClient } from '@angular/common/http';
import { SchoolAdminService } from '../services/school-admin/school-admin.service';

@Component({
  selector: 'app-studentcheckout',
  templateUrl: './studentcheckout.component.html',
  styleUrls: ['./studentcheckout.component.scss']
})
export class StudentcheckoutComponent implements OnInit {
  languageID : any = "";
  showApplyBtn : boolean = true;
  showRemoveBtn : boolean = false;
  checkoutForm : any ;
  teacherPlans : any = [] ;
  selectedPlans : any = [] ;
  IsFormLoadFirstTime : boolean = true;
  rememberMe: number = 0;
  successMessage: any = "";
  errorMessage : any = "";
  plan_id : any = "STUDENT-MASTER-MONTHLY";
  isTermsCondition: boolean = true;
  form_detail: any = {};
  plan_detail: any = {};
  price: any = 0;
  weekly_english_pdf_price: any = 0;
  weekly_english_pdf: boolean = false;
  assessment_coaching_session_price: any = 0;
  assessment_coaching_session: boolean = false;
  agree_promotional_email:boolean = false;
  terms_condition:boolean = false;
  env = environment;
  public userTz: string;
  showPlans: boolean;
  constructor(private http:HttpClient,private _commonHelperService : CommonHelperService,private fb: FormBuilder, public commonHelperService : CommonHelperService ,
    private spinner: NgxSpinnerService,private _student_Service : StudentService, private ajaxService: AjaxService, private route: ActivatedRoute,private router: Router, private mixpanelService: MixpanelService,private schooladminservice: SchoolAdminService,private ref: ChangeDetectorRef  ) {
      this.userTz = moment.tz.guess();
     }

  applyCoupon(){  
    let coupon = this.couponCode.value;
    if (coupon!='PocketPower50') {
      this.successMessage = "";
      this.errorMessage = "Invalid coupon code."                
      setTimeout(()=>{
        this.errorMessage = "";
        this.successMessage = "";
      },environment.MSG_TIMEOUT);
      return;
    }
    
              
    let adminInfo = this.schooladminservice.GetSchool_AdminUserInfoFromLocalStorage();
    let token: any = adminInfo["school_admin_token"];
    this.ajaxService
      .ApiCallWithLocalization(
        '',
        "/stripe-connect/applyCoupon/"+coupon,
        "get",
        token
      )
      .subscribe(
        result => {
                                          
          if (result.status) {
            this.showApplyBtn = false;
            this.showRemoveBtn = true;   
            this.successMessage = "Coupon applied successfully.";
            this.errorMessage = "";     
            this.updateStudentFinalPriceButtonPrice();            
            setTimeout(()=>{
              this.errorMessage = "";
              this.successMessage = "";
            },environment.MSG_TIMEOUT);         
          }else{
            this.successMessage = "";
            this.errorMessage = result.message;                 
            setTimeout(()=>{
              this.errorMessage = "";
              this.successMessage = "";
            },environment.MSG_TIMEOUT);
          }   
                                              
        },
        error => {
                  
        }
      );
  }

  fetchTeacherPlans(){  
    if(this.route.snapshot.queryParams && this.route.snapshot.queryParams.id) {                  
      var param = this.route.snapshot.queryParams.id; 
      var paramArray = param.split("-");
      var schoolId = paramArray.slice(-1)[0];                          
    }   else{
      alert("Something went wrong, please try again later.");
      return;
    } 

  

    let adminInfo = this.schooladminservice.GetSchool_AdminUserInfoFromLocalStorage();
    let token: any = adminInfo["school_admin_token"];
    this.ajaxService
      .ApiCallWithLocalization(
        {'email':schoolId},
        "/teacher-plans/plans-by-email",
        "post",
        token
      )
      .subscribe(
        result => {                                                                 
          if (result.status) {
            this.teacherPlans = result.data;
            if (this.teacherPlans.length) {
              this.showPlans = true;
            }else{
              this.showPlans = false;
            }
          }                                  
        },
        error => {
                  
        }
      );
  }

  removeCoupon(){
    this.couponCode.value = '';
    this.checkoutForm.get('couponCode').reset();
    this.showApplyBtn = true;
    this.showRemoveBtn = false;
    this.updateStudentFinalPriceButtonPrice();
  }


  updateStudentFinalPriceButtonPrice(){
    this.price = parseInt(this.plan_detail.price);

    if (this.couponCode.value && this.couponCode.value=='PocketPower50' && !this.showApplyBtn) {
      this.price = this.price *0.5 // 50% discount
    }

    this.selectedPlans.forEach(element => {
      this.price+=parseInt(element.amount);
    });
    document.getElementById('buy_now').innerHTML = "Buy Now "+this.plan_detail.currency+parseFloat(this.price).toFixed(2);
   
  }

  validateCheckboxes(event){
    if(event.target.name == 'terms_condition'){
      this.isTermsCondition = !event.target.checked;
    }
  }

  checkBoxSelectionSet(option, event){
    if(event.target.checked) {
      this.selectedPlans.push(option);
    } else {
      for(var i=0 ; i < this.teacherPlans.length; i++) {
        if(this.selectedPlans[i].id == option.id) {
          this.selectedPlans.splice(i,1);
        }
      }
    }
    this.updateStudentFinalPriceButtonPrice();
    

  }

  onProductChange(event:any){
   setTimeout(()=>document.getElementById('fname').click(),5);
    this.plan_id = event.target.value;
    this.LoadProductDetail();   
    
  }



  handler:any = null;

  // password and confirm password validation
  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

  ngOnInit() {
    // 
    this.reloadData();
    this.fetchTeacherPlans();
  }

  reloadData(){
        this.plan_id = this.route.snapshot.paramMap.get('plan_id') || "STUDENT-MASTER-MONTHLY";
        this.plan_id = this.plan_id.toUpperCase();
        this.checkoutForm = this.fb.group({        
        fname:['', [Validators.required]],
        lname:['', [Validators.required]],
        email:['', [Validators.required]],
        username:['', [Validators.required]],
        password:['', [Validators.required, Validators.minLength(8)]],
        confirm_password:['', [Validators.required]],
        payment_method:['stripe', [Validators.required]],
        terms_condition:['', [Validators.required]],
        couponCode:[''],

      },{
        validator: this.ConfirmedValidator('password', 'confirm_password')
      }
       );

      if(environment.Enable_MixPanel)
      {
        this.mixpanelService.track("View Page Student Login");
      }

      this.loadStripe();
      this.LoadProductDetail();
  }

  async LoadProductDetail(){
    
    // ag2222
    // console.log("ag2222 : plan id ",this.plan_id);
    let  response = await this.http.post<any>(this.env.API_URL+ '/v1/subscription/planDetails',{plan_id:this.plan_id}).toPromise();

    // return response;

    if(response.response.raws.data.status == false){
      // alert('Plan not found');
      this.router.navigate(['/internal-server-error']);
    }else{
      this.plan_detail = response.response.raws.data.dataset;
      this.price = this.plan_detail.price;      
      // console.log("LoadProductDetail : ",this.price,this.plan_detail );
      this.updateStudentFinalPriceButtonPrice();
    }
    
  }

  loadStripe() {
    // console.log("loading initailay  Load");
    if(!window.document.getElementById('stripe-script')) {
      let s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: this.env.stripePublishkey,
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // console.log("initial Load : ",token)
            // alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }

  /* Password getter methord */
  get fname(){
    return this.checkoutForm.get('fname');
  }
  
  /* Password getter methord */
  get couponCode(){
    return this.checkoutForm.get('couponCode');
  }

  /* Password getter methord */
  get lname(){
    return this.checkoutForm.get('lname');
  }
  /* Password getter methord */
  get email(){
    return this.checkoutForm.get('email');
  }
  /* Password getter methord */
  get username(){
    return this.checkoutForm.get('username');
  }
  /* Password getter methord */
  get password(){
    return this.checkoutForm.get('password');
  }

  /* Password getter methord */
  get confirm_password(){
    return this.checkoutForm.get('confirm_password');
  }

  /* Password getter methord */
  get payment_method(){
    return this.checkoutForm.get('payment_method');
  }

  /* Password getter methord */
  // get plan_id(){
  //   console.log("plan_id : ",this.checkoutForm.get('plan_id'),this.checkoutForm.get('plan_id').value);
  //   return this.checkoutForm.get('plan_id');
  // }


  get f(){
    return this.checkoutForm.controls;
  }

  async stripePay(){

    try {
      let  res = await this.http.post<any>(this.env.API_URL+ '/v1/checkout/stripe-pay',this.form_detail).toPromise();
      // check for response
     
      // check payment response for error
      if(this.plan_detail.plan_user_type == 'S'){
        this.router.navigate(['/students']);
      }else{
        this.router.navigate(['/schools']);
      }
      console.log("Paid", res);
      alert('Payment Success, Please login to your account!!');

    } catch (err) {
     
        this.successMessage = "";
          this.errorMessage = err.error.response.raws.error_message;
        setTimeout(()=>{
            this.errorMessage = "";
            this.successMessage = "";
          },environment.MSG_TIMEOUT);

    }
  }

  getStripToken() {
    console.log('getStripToken : ',this.plan_detail,this.form_detail);
    let handler = (<any>window).StripeCheckout.configure({
      image: 'https://d1fe9qmcit6u5o.cloudfront.net/assets/images/logo-dark.svg',
      key: this.env.STRIPE_KEY,
      locale: 'auto',
      // token: function (token: any) {
      token: token =>{
        this.form_detail.stripe_token = token;
        this.form_detail.stripe_token_id = token.id;
        console.log(this.form_detail);
        this.stripePay();
      }
    });
    handler.open({
      name: this.plan_detail.plan_name,
      description: this.plan_detail.plan_description,
      amount: this.price * 100,
      email:this.form_detail.email
    });
  }

  async checkUserExist(email){
    let  res = await this.http.post<any>(this.env.API_URL+ '/v1/student/check-exist',email).toPromise();
    return res.response.raws.data.status;
  }
  

  /**
   @Date : 13-11-2019
   @Developer : Deepak Visani
   @Purpose : [loginUser making user login and save user info to local storage]
   */
   async checkoutUser(){
    this.IsFormLoadFirstTime = false;
    // console.log('checkoutUser start',this.checkoutForm.valid);

    if(this.checkoutForm.valid){

      //
      // console.log('inside valid',this.form_detail);

      // start :: AG :: prepare form input parameter for checkout
      this.form_detail.plan_id    =  this.plan_id.value;
      this.form_detail.fname    =  this.fname.value;
      this.form_detail.lname    =  this.lname.value;
      this.form_detail.email    =  this.email.value;
      this.form_detail.agree_promotional_email    =  this.agree_promotional_email;
      this.form_detail.payment_method    =  this.payment_method.value; // radi button paypal/stripe
      this.form_detail.terms_condition    =  this.terms_condition; // true false
      this.form_detail.price    =  this.price;
      this.form_detail.plan_id    =  this.plan_id;
      //onrecurring  payment
      if(this.plan_detail.billing_interval != 'O'){
       console.log(this.plan_detail)

        this.form_detail.username    =  this.username.value;
        this.form_detail.password    =  this.password.value;
        this.form_detail.confirm_password    =  this.confirm_password.value;
        this.form_detail.password    =  this.password.value;
        this.form_detail.password    =  this.password.value;
        this.form_detail.couponCode  =  this.couponCode.value;
        let selectedPlanIds = [];
        if (this.selectedPlans.length) {
          this.selectedPlans.forEach(element => {
            selectedPlanIds.push(element.id);
          });
          if (selectedPlanIds.length) {
            this.form_detail.selectedPlanIds  =  selectedPlanIds.toString();
          }
        }

       

        
        // for student payment
        if(this.plan_detail.plan_user_type == 'S'){
          this.form_detail.weekly_english_pdf_price    =  this.weekly_english_pdf_price;
          this.form_detail.assessment_coaching_session_price    =  this.assessment_coaching_session_price;
        }else{
          this.form_detail.crossell    =  {};
        }
      }
      // stop :: AG :: prepare form input parameter for checkout
      // console.log("checkoutUser ",this.form_detail);

      //  payment integration
      if(this.form_detail.payment_method == 'stripe' || this.form_detail.payment_method == 'paypal' ){
        //recurring time payment,  need to check user already exist, since user will be created
        if(this.plan_detail.billing_interval != 'O'){
          let  user_exist_status = await this.checkUserExist(this.form_detail.email );
           console.log(user_exist_status);
          if(user_exist_status){
           
              // user is not allowed to make payment as user account is already created, ask him to login
              alert("Please use your credential to login");
              let  login_url = (this.plan_detail.plan_user_type == 'T')?'/schools':'/students';
              this.router.navigate([login_url]);
              return;
          }
          if(this.form_detail.payment_method == 'stripe'){
                var form = document.createElement("form");
                var plan_id = document.createElement("input"); 
                var plan_title = document.createElement("input");  
                var plan_user_type = document.createElement("input");  
                var plan_name = document.createElement("input");  
                var user_type = document.createElement("input");  
                var plan_description = document.createElement("input");  
                var fname = document.createElement("input");  
                var lname = document.createElement("input");  
                var email = document.createElement("input");  
                var price = document.createElement("input");  
                var username = document.createElement("input");  
                // var weekly_english_pdf_price = document.createElement("input");  
                // var assessment_coaching_session_price = document.createElement("input"); 
                var password =  document.createElement("input");  
                var schoolId =  document.createElement("input");  
                var selectedPlanIds =  document.createElement("input");  
                var couponField =  document.createElement("input");  


                form.method = "post";
                form.action = this.env.API_URL+"/auth/checkout";   

                if (this.form_detail.selectedPlanIds) {
                  selectedPlanIds.value=this.form_detail.selectedPlanIds;
                  selectedPlanIds.name="selectedPlanIds";
                  form.appendChild(selectedPlanIds);
                }

                plan_id.value=this.form_detail.plan_id;
                plan_id.name="plan_id";
                form.appendChild(plan_id);  
                couponField.value=this.form_detail.couponCode;
                couponField.name="couponCode";
                form.appendChild(couponField);  
            
                password.value=this.form_detail.password;
                password.name="password";
                form.appendChild(password); 

                password.value=this.form_detail.password;
                password.name="password";
                form.appendChild(password); 

                plan_title.value=this.plan_detail.plan_title;
                plan_title.name="plan_title";
                form.appendChild(plan_title);  


                plan_user_type.value=this.plan_detail.plan_user_type;
                plan_user_type.name="plan_user_type";
                form.appendChild(plan_user_type);  

                plan_name.value=this.plan_detail.plan_name;
                plan_name.name="plan_name";
                form.appendChild(plan_name);  

                user_type.value=this.plan_detail.user_type;
                user_type.name="user_type";
                form.appendChild(user_type);  

                plan_description.value=this.plan_detail.plan_description;
                plan_description.name="plan_description";
                form.appendChild(plan_description);  

                fname.value=this.form_detail.fname;
                fname.name="fname";
                form.appendChild(fname);  

                lname.value=this.form_detail.lname;
                lname.name="lname";
                form.appendChild(lname);  

                email.value=this.form_detail.email;
                email.name="email";
                form.appendChild(email); 

                price.value=this.form_detail.price;
                price.name="price";
                form.appendChild(price); 

                username.value=this.form_detail.username;
                username.name="username";
                form.appendChild(username); 

                // weekly_english_pdf_price.value=this.form_detail.weekly_english_pdf_price;
                // weekly_english_pdf_price.name="weekly_english_pdf_price";
                // form.appendChild(weekly_english_pdf_price); 

                // assessment_coaching_session_price.value=this.form_detail.assessment_coaching_session_price;
                // assessment_coaching_session_price.name="assessment_coaching_session_price";
                // form.appendChild(assessment_coaching_session_price); 

                if(this.route.snapshot.queryParams && this.route.snapshot.queryParams.id) { 
                  let param = this.route.snapshot.queryParams.id; 
                  let paramArray = param.split("-");
                  let schoolIdVal = paramArray.slice(-1)[0];                 
                  schoolId.value= schoolIdVal;
                  schoolId.name="schoolId";
                  form.appendChild(schoolId);                                   
               }else{
                alert("You are not using a valid url, please contact your teacher.");
                return;
               }            
                document.body.appendChild(form);            
                form.submit();                            
          }else{
            alert("1 Please contact support team to make payment");
          }
        }

      }else{
          // payment method not associated
          alert("2 Please contact support team to make payment");
      }



      //after payment, just allow user to download digital bundle products
      if(this.plan_detail.billing_interval == 'O'){

      }else{
        // recurring payment, monthly or yearly, User account need to create after payment successfull

      }

    }//.End if(this.checkoutForm.valid)
    else{
      // console.log("form error");
    }
  }//.End loginUser()

  planIdChangedHandler(ev){
    this.plan_id = ev;
    this.reloadData();
  }

}
