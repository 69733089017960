import { Injectable,EventEmitter } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import 'firebase/messaging';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  checkCurrentSchedule = new EventEmitter<any>();
  constructor(private angularFireMessaging: AngularFireMessaging) {
    angularFireMessaging.onMessage((payload) => {
      this.currentMessage=payload;
      //console.log(this.currentMessage);
      this.checkCurrentSchedule.emit();
      //console.log("foreground == ", notification);
    })
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        //console.log(token);
        localStorage.setItem('devicetoken',token);
      },
      (err) => {
        localStorage.setItem('devicetoken','');
        //console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        //console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}