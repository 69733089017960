import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appToLowerCaseName]'
})
export class ToLowerCaseNameDirective {

  @Input('acceptNumbers') acceptNumbers = false;

  specialCharsArray: any = [
    '!', '"', '#', '$', '%', '&', '(', ')', '*', '+', ',', '-', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', '\\'
    , ']', '^', '_', '`', '{', '|', '}', '~', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'
  ]

  specialCharsArrayWithoutNum: any = [
    '!', '"', '#', '$', '%', '&', '(', ')', '*', '+', ',', '-', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', '\\'
    , ']', '^', '_', '`', '{', '|', '}', '~'
  ]

  constructor(private el: ElementRef) { }

  @HostListener('paste', ['$event']) onEvent(event) {
    let e = <KeyboardEvent>event;
    e = e || event;
    setTimeout(() => {
      let str = this.RemoveSpecialChars(this.el.nativeElement.value);
      this.el.nativeElement.value = this.ConvertToLowerCase(str);
    }, 10);
  }

  @HostListener('keypress', ['$event', '$event.target.value']) onKeyDown(event, val) {
    let e = <KeyboardEvent>event;
    e = e || event;
    //console.log(e.keyCode);

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything

      var inp = String.fromCharCode(e.keyCode);

      if (this.acceptNumbers == false) {
        if (!this.specialCharsArray.includes(inp)) {
          return;
        }
        else { e.preventDefault(); }
      }
      else {
        if (!this.specialCharsArrayWithoutNum.includes(inp)) {
          return;
        }
        else { e.preventDefault(); }
      }

    }
    var inp = String.fromCharCode(e.keyCode);
    if (this.acceptNumbers == false) {
      if (!this.specialCharsArray.includes(inp)) {
        return;
      }
      else { e.preventDefault(); }
    }
    else {
      if (!this.specialCharsArrayWithoutNum.includes(inp)) {
        return;
      }
      else { e.preventDefault(); }
    }
  }

  @HostListener('keyup', ['$event', '$event.target.value']) onKeyUp(event, val) {
    let e = <KeyboardEvent>event;
    setTimeout(() => {
      this.el.nativeElement.value = this.ConvertToLowerCase(this.el.nativeElement.value);
    }, 10);

  }

  ConvertToLowerCase(str: string) {
    if (str == undefined || str == null || str == "") return "";
    else {
      return str.toLowerCase();
    }
  }

  RemoveSpecialChars(str: string) {
    let result: string = "";
    for (var i = 0; i < str.length; i++) {
      let chr = str.charAt(i);

      if (this.acceptNumbers == false) {
        if (!this.specialCharsArray.includes(chr)) {
          result += chr;
        }
        else { }
      }
      else {
        if (!this.specialCharsArrayWithoutNum.includes(chr)) {
          result += chr;
        }
        else { }
      }
    }
    return result;
  }

}
