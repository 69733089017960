import { Component, OnInit } from '@angular/core';
import { CommonHelperService } from '../services/common-helper.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { AjaxService } from '../services/ajax.service';
import { StudentService } from '../services/student/student.service';
import moment from 'moment-timezone';
import { MixpanelService } from '../services/mixpanel.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-studentquiz',
  templateUrl: './studentquiz.component.html',
  styleUrls: ['./studentquiz.component.scss']
})
export class StudentquizComponent implements OnInit {

  languageID : any = "";
  checkoutForm : any ;
  IsFormLoadFirstTime : boolean = true;
  rememberMe: number = 0;
  successMessage: any = "";
  errorMessage : any = "";
  plan_id : any = "QUIZ_STUDENT_FULL_ACCESS_TO_COURSE_Y";
  isTermsCondition: boolean = true;
  form_detail: any = {};
  plan_detail: any = {};
  price: any = 0;
  weekly_english_pdf_price: any = 0;
  weekly_english_pdf: boolean = false;
  assessment_coaching_session_price: any = 0;
  assessment_coaching_session: boolean = false;
  agree_promotional_email:boolean = false;
  terms_condition:boolean = false;
  // agree_promotional_email: any = 0;
  // terms_condition: any = 0;

  env = environment;

  public userTz: string;


  updateStudentFinalPriceButtonPrice(){
    this.price = parseInt(this.plan_detail.price)+parseInt(this.weekly_english_pdf_price)+parseInt(this.assessment_coaching_session_price);
    // console.log("updateStudentFinalPriceButtonPrice : ",this.price,this.plan_detail.price,this.weekly_english_pdf_price,this.assessment_coaching_session_price,this.plan_detail.price);
    document.getElementById('buy_now').innerHTML = "Buy Now "+this.plan_detail.currency+parseFloat(this.price).toFixed(2);
  }

  checkBoxSelectionSet(event:any){
    // console.log("checkBoxSelectionSet : ",event.target.name,event.target.checked,this[event.target.name]);
    this[event.target.name] = event.target.checked;

    if(event.target.name == 'weekly_english_pdf'){
      this[event.target.name+'_price'] = (event.target.checked)?4.00:0;
      this.updateStudentFinalPriceButtonPrice();
      // console.log("weekly_english_pdf :  ",this[event.target.name+'_price']);
    }

    if(event.target.name == 'assessment_coaching_session'){
      this[event.target.name+'_price'] = (event.target.checked)?69.00:0;
      this.updateStudentFinalPriceButtonPrice();
      // console.log("assessment_coaching_session :  ",this[event.target.name+'_price']);
    }

    if(event.target.name == 'terms_condition'){
      this.isTermsCondition = !event.target.checked;
    }

  }

  onProductChange(event:any){
    this.plan_id = event.target.value;
    this.LoadProductDetail();
    // console.log(" event on priooduct chnage :",event.target.name,event.target.value);
  }

  constructor(private http:HttpClient,private _commonHelperService : CommonHelperService,private fb: FormBuilder, public commonHelperService : CommonHelperService ,
    private spinner: NgxSpinnerService,private _student_Service : StudentService, private ajaxService: AjaxService, private route: ActivatedRoute,private router: Router, private mixpanelService: MixpanelService) {
      this.userTz = moment.tz.guess();
     }

  handler:any = null;

  // password and confirm password validation
  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

  ngOnInit() {

    this.checkoutForm = this.fb.group({
        // fname:['anil', [Validators.required]],
        // lname:['gupta', [Validators.required]],
        // email:['aniltestmailinator@mailinator.com', [Validators.required]],
        // username:['aniltestmailinator', [Validators.required]],
        // password:['pocket@1234', [Validators.required, Validators.minLength(8)]],
        // confirm_password:['pocket@1234', [Validators.required]],
        // payment_method:['stripe', [Validators.required]],
        // terms_condition:['', [Validators.required]],
        fname:['', [Validators.required]],
        lname:['', [Validators.required]],
        email:['', [Validators.required]],
        username:['', [Validators.required]],
        password:['', [Validators.required, Validators.minLength(8)]],
        confirm_password:['', [Validators.required]],
        payment_method:['stripe', [Validators.required]],
        terms_condition:['', [Validators.required]],

      },{
        validator: this.ConfirmedValidator('password', 'confirm_password')
      }
       );

      if(environment.Enable_MixPanel)
      {
        this.mixpanelService.track("View Page Student Login");
      }
      this.loadStripe();
      this.LoadProductDetail();

  }

  async LoadProductDetail(){
    // ag2222
    // console.log("ag2222 : plan id ",this.plan_id);
    let  response = await this.http.post<any>(this.env.API_URL+ '/v1/subscription/planDetails',{plan_id:this.plan_id}).toPromise();

    // return response;

    if(response.response.raws.data.status == false){
      // alert('Plan not found');
      this.router.navigate(['/internal-server-error']);
    }else{
      this.plan_detail = response.response.raws.data.dataset;
      this.price = this.plan_detail.price;
      // console.log("LoadProductDetail : ",this.price,this.plan_detail );
      this.updateStudentFinalPriceButtonPrice();
    }
  }

  loadStripe() {
    // console.log("loading initailay  Load");
    if(!window.document.getElementById('stripe-script')) {
      let s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: this.env.stripePublishkey,
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // console.log("initial Load : ",token)
            // alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }

  /* Password getter methord */
  get fname(){
    return this.checkoutForm.get('fname');
  }

  /* Password getter methord */
  get lname(){
    return this.checkoutForm.get('lname');
  }
  /* Password getter methord */
  get email(){
    return this.checkoutForm.get('email');
  }
  /* Password getter methord */
  get username(){
    return this.checkoutForm.get('username');
  }
  /* Password getter methord */
  get password(){
    return this.checkoutForm.get('password');
  }

  /* Password getter methord */
  get confirm_password(){
    return this.checkoutForm.get('confirm_password');
  }

  /* Password getter methord */
  get payment_method(){
    return this.checkoutForm.get('payment_method');
  }

  /* Password getter methord */
  // get plan_id(){
  //   console.log("plan_id : ",this.checkoutForm.get('plan_id'),this.checkoutForm.get('plan_id').value);
  //   return this.checkoutForm.get('plan_id');
  // }


  get f(){
    return this.checkoutForm.controls;
  }

  async stripePay(){

    try {
      let  res = await this.http.post<any>(this.env.API_URL+ '/v1/checkout/stripe-pay',this.form_detail).toPromise();
      // check for response
      this.spinner.hide();
      // check payment response for error
      if(this.plan_detail.plan_user_type == 'S'){
        this.router.navigate(['/students']);
      }else{
        this.router.navigate(['/schools']);
      }
      console.log("Paid", res);
      alert('Payment Success, Please login to your account!!');

    } catch (err) {
      this.spinner.hide();
        this.successMessage = "";
          this.errorMessage = err.error.response.raws.error_message;
        setTimeout(()=>{
            this.errorMessage = "";
            this.successMessage = "";
          },environment.MSG_TIMEOUT);

    }
  }

  getStripToken() {
    console.log('getStripToken : ',this.plan_detail,this.form_detail);
    let handler = (<any>window).StripeCheckout.configure({
      image: 'https://d1fe9qmcit6u5o.cloudfront.net/assets/images/logo-dark.svg',
      key: this.env.STRIPE_KEY,
      locale: 'auto',
      // token: function (token: any) {
      token: token =>{
        this.form_detail.stripe_token = token;
        this.form_detail.stripe_token_id = token.id;
        console.log(this.form_detail);
        this.stripePay();
      }
    });
    handler.open({
      name: this.plan_detail.plan_name,
      description: this.plan_detail.plan_description,
      amount: this.price * 100,
      email:this.form_detail.email
    });
  }

  async checkUserExist(email){
    let  res = await this.http.post<any>(this.env.API_URL+ '/v1/student/check-exist',email).toPromise();
    return res.response.raws.data.status;
  }

  /**
   @Date : 13-11-2019
   @Developer : Deepak Visani
   @Purpose : [loginUser making user login and save user info to local storage]
   */
   async checkoutUser(){
    this.IsFormLoadFirstTime = false;
    // console.log('checkoutUser start',this.checkoutForm.valid);

    if(this.checkoutForm.valid){

      this.spinner.show();
      // console.log('inside valid',this.form_detail);

      // start :: AG :: prepare form input parameter for checkout
      this.form_detail.plan_id    =  this.plan_id.value;
      this.form_detail.fname    =  this.fname.value;
      this.form_detail.lname    =  this.lname.value;
      this.form_detail.email    =  this.email.value;
      this.form_detail.agree_promotional_email    =  this.agree_promotional_email;
      this.form_detail.payment_method    =  this.payment_method.value; // radi button paypal/stripe
      this.form_detail.terms_condition    =  this.terms_condition; // true false
      this.form_detail.price    =  this.price;
      this.form_detail.plan_id    =  this.plan_id;

      //onrecurring  payment
      if(this.plan_detail.billing_interval != 'O'){
        this.form_detail.username    =  this.username.value;
        this.form_detail.password    =  this.password.value;
        this.form_detail.confirm_password    =  this.confirm_password.value;
        this.form_detail.password    =  this.password.value;
        this.form_detail.password    =  this.password.value;
        // for student payment
        if(this.plan_detail.plan_user_type == 'S'){
          this.form_detail.weekly_english_pdf_price    =  this.weekly_english_pdf_price;
          this.form_detail.assessment_coaching_session_price    =  this.assessment_coaching_session_price;
        }else{
          this.form_detail.crossell    =  {};
        }
      }
      // stop :: AG :: prepare form input parameter for checkout
      // console.log("checkoutUser ",this.form_detail);

      //  payment integration
      if(this.form_detail.payment_method == 'stripe' || this.form_detail.payment_method == 'paypal' ){
        //recurring time payment,  need to check user already exist, since user will be created
        if(this.plan_detail.billing_interval != 'O'){
          let  user_exist_status = await this.checkUserExist(this.form_detail.email );
          // console.log(user_exist_status);
          if(user_exist_status){
            console.log("user already exist");
              // user is not allowed to make payment as user account is already created, ask him to login
              alert("Please use your credential to login");
              let  login_url = (this.plan_detail.plan_user_type == 'T')?'/schools':'/students';
              this.router.navigate([login_url]);
          }else{
            // console.log("check 1");
          }
          // console.log('this.form_detail',this.form_detail);
          switch(this.form_detail.payment_method){
            case 'stripe':
                console.log("caling stripe");
                this.getStripToken();
                break;
            case 'paypal':
                break;
             default:
                // payment method not associated
                alert("1 Please contact support team to make payment");
          }
        }

      }else{
          // payment method not associated
          alert("2 Please contact support team to make payment");
      }



      //after payment, just allow user to download digital bundle products
      if(this.plan_detail.billing_interval == 'O'){

      }else{
        // recurring payment, monthly or yearly, User account need to create after payment successfull

      }

    }//.End if(this.checkoutForm.valid)
    else{
      // console.log("form error");
    }
  }//.End loginUser()


}

