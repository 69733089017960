import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  /**
   * constructor
   * get mixpanel token and initialize
   */
  constructor() {
    this.init();
  }

  /**
   * Initialize mixpanel.
   */
  init(): void {
    mixpanel.init('c2acaa2376eb51f5dac4402d144dc688');
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
  /**
    * Create new Alias for user
    * Call this method only once in user lifecycle
    *
    * @param {string} alias
    */
  createAlias(alias: any) {
    mixpanel.alias(alias, mixpanel.get_distinct_id());
  }

  /**
   * Identify User
   *
   * @param {string} alias
   */
  identify(alias: any) {
    mixpanel.identify(alias);
  }

  /**
    * setPeople
    * Store user profiles in Mixpanel's People Analytics product
    * @param {Object} properties
    */
  setPeople(properties: any = {}): void {
    mixpanel.people.set(properties);
  }

  /**
    * trackCharge
    * charge a user in Mixpanel's People Analytics product
    */
  trackCharge(price: any): void {
    mixpanel.people.track_charge(price);
  }

  /**
    * increment
    * Increment/decrement numeric people analytics properties.
    */
  increment(flag:any,price: any): void {
    mixpanel.people.increment(flag,price);
  }

}
