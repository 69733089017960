import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {

    numberCharsArray : any =[
        '1','2','3','4','5','6','7','8','9','0'
       ]
     
     
       constructor(private el: ElementRef) { }
     
       @HostListener('paste',['$event']) onEvent(event) {
         let e = <KeyboardEvent> event;
         e = e || event;
         setTimeout(()=>{
           let str =  this.RemoveCharactersNonNumber(this.el.nativeElement.value) ;
           this.el.nativeElement.value = (str) ;
         },10);
       }
     
     
       @HostListener('keypress', ['$event','$event.target.value']) onKeyDown(event,val) {
         let e = <KeyboardEvent> event;
         e = e || event;
          //console.log(e.keyCode);
          
           if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
             // Allow: Ctrl+A
             (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
             // Allow: Ctrl+C
             (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
             // Allow: Ctrl+V
             (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
             // Allow: Ctrl+X
             (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
             // Allow: home, end, left, right
             (e.keyCode >= 35 && e.keyCode <= 39)) {
               // let it happen, don't do anything
     
                   var inp = String.fromCharCode(e.keyCode); 
                   if(this.numberCharsArray.includes(inp)){
                     return;
                   }
                   else{  e.preventDefault();  }
             }
     
             var inp = String.fromCharCode(e.keyCode); 
             if(this.numberCharsArray.includes(inp)){
               return;
             }
             else{ e.preventDefault();  }
     
     
             // Ensure that it is a number and stop the keypress
             // if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
             //     e.preventDefault();
             // }  
       }
     
     

     
     
       
     
       RemoveCharactersNonNumber(str : string){
         let result : string = "";
         for (var i = 0; i < str.length; i++) {
           let chr = str.charAt(i);
           if(this.numberCharsArray.includes(chr)){
             result+= chr ;
           }
         }
         return result;
       }

}
