import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appUppercase]',
  host: {
    '(input)': '$event'
  }
})
export class UppercaseDirective {

  lastValue: string;

  constructor(public ref: ElementRef) { }//{ [ ( - : . / _ ) ] } @ # &
  specialCharsArray : any =[
    '!', '"' ,'$' ,'%' ,'*' ,'+' ,',' ,';' ,'<' ,'=' ,'>' ,'?' ,'\\'
      ,'^' ,'`' ,'|' ,'~' 
   ];
  @HostListener('input', ['$event']) onInput($event) 
  {
    var start = $event.target.selectionStart;
    var end = $event.target.selectionEnd;
    $event.target.value = $event.target.value.toUpperCase();
    $event.target.setSelectionRange(start, end);
    $event.preventDefault();

    if (!this.lastValue || (this.lastValue && $event.target.value.length > 0 && this.lastValue !== $event.target.value)) {
      this.lastValue = this.ref.nativeElement.value = $event.target.value;
      // Propagation
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      event.target.dispatchEvent(evt);
    }
  }
  @HostListener('keypress', ['$event','$event.target.value']) onKeyDown(event,val) {
    let e = <KeyboardEvent> event;
    e = e || event;
     //console.log(e.keyCode);
     
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything

              var inp = String.fromCharCode(e.keyCode); 
              if(!this.specialCharsArray.includes(inp)){
                return;
              }
              else{  e.preventDefault();  }
        }

        var inp = String.fromCharCode(e.keyCode); 
        if(!this.specialCharsArray.includes(inp)){
          return;
        }
        else{ e.preventDefault();  }


        // Ensure that it is a number and stop the keypress
        // if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        //     e.preventDefault();
        // }  
  }
  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    console.log(e.clipboardData);
    var clipboardData = e.clipboardData;
    let pastedData = clipboardData.getData('Text');

    //e.preventDefault();
    
    if(!this.specialCharsArray.includes(pastedData)){
      return;
    }
    else{  e.preventDefault();  }
  }
}