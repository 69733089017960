import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map , timeout,catchError } from 'rxjs/operators';
import { Observable, of,throwError } from 'rxjs'
import { environment } from '../../environments/environment';
import { CommonHelperService } from '../services/common-helper.service';
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AjaxService {

  constructor(private router: Router,private http: HttpClient,private _commonHelperService : CommonHelperService) { }



  queryParams(source : any) {
    var array = [];		
    for(var key in source) {
     array.push(encodeURIComponent(key) + "=" + encodeURIComponent(source[key]));
    }		
    return array.join("&");
  }

  

 /**
   @Date : 05-12-2019
   @Developer : DeepJyoty
   @param data : data for post
   @param url : api url to call like '/login'
   @param method : method to call like post or put or get or delete
  */
  ApiCallWithLocalization(data : any, url: any, method : any, headertoken:any = undefined ) {
    let lang = this._commonHelperService.GetLanguage();
     return this.ApiCall(data , url, method , headertoken ,lang);
  }


 /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @param data : data for post
   @param url : api url to call like '/login'
   @param method : method to call like post or put or get or delete
  */
  ApiCall(data : any, url: any, method : any, headertoken:any = undefined ,localization: any ='en', IsUrlrawformat : boolean = false) {
    
    if(IsUrlrawformat == false){
      url = environment.API_URL +'/'+ environment.API_VERSION + url ;
    }
    
    
    if(headertoken==undefined){
    
      if(method=='post'){
        return this.http.post(url, data ,{ observe: 'response'})
        .pipe(
          timeout(environment.AJAX_TIMEOUT),
          catchError((e, c) => { return throwError(e) }),
          map((response: any) => { 
            var responseobj = JSON.parse(JSON.stringify(response.body));
            responseobj.status = response.status;
            return responseobj; 
          })
          )
        
      }
      else if(method=='get'){
        return this.http.get(url,{ observe: 'response'})
        .pipe(
          timeout(environment.AJAX_TIMEOUT),
          catchError((e, c) => { return throwError(e) }),
          map((response: any) => { 
            var responseobj = JSON.parse(JSON.stringify(response.body));
            responseobj.status = response.status;
            return responseobj; 
          })
          )
        
      }
      else if(method=='put'){
        return this.http.put(url,data,{ observe: 'response'})
        .pipe(
          timeout(environment.AJAX_TIMEOUT),
          catchError((e, c) => { return throwError(e) }),
          map((response: any) => { 
            var responseobj = JSON.parse(JSON.stringify(response.body));
            responseobj.status = response.status;
            return responseobj; 
          })
          )
        
      }
      else if(method=='delete'){
        return this.http.delete(url,{ observe: 'response'})
        .pipe(
          timeout(environment.AJAX_TIMEOUT),
          catchError((e, c) => { return throwError(e) }),
          map((response: any) => { 
            var responseobj = JSON.parse(JSON.stringify(response.body));
            responseobj.status = response.status;
            return responseobj; 
          })
          )
        
      }
      else{
        return this.http.post(url,data,{ observe: 'response'})
        .pipe(
          timeout(environment.AJAX_TIMEOUT),
          catchError((e, c) => { return throwError(e) }),
          map((response: any) => { 
            var responseobj = JSON.parse(JSON.stringify(response.body));
            responseobj.status = response.status;
            return responseobj; 
          })
          )
        
      }
    }
    else{

          
        let httpHeaderValue = new HttpHeaders();
        httpHeaderValue = httpHeaderValue
                          .set('Authorization','Bearer ' + headertoken)
                          //.set('Content-Type', 'application/json')
                          .set('X-localization', localization);
                       

        if(method=='post'){
          return this.http.post(url, data,{ headers: httpHeaderValue, observe: 'response'})
          
          .pipe(
            //delay(5000),
            timeout(environment.AJAX_TIMEOUT),
            catchError((e, c) => { return throwError(e) }),
            map((response: any) => { 
              var responseobj = JSON.parse(JSON.stringify(response.body));
              responseobj.status = response.status;
              return responseobj; 
            })
            )
          
        }
        else if(method=='get'){
          return this.http.get(url, { headers: httpHeaderValue, observe: 'response'})
          .pipe(
            timeout(environment.AJAX_TIMEOUT),
            catchError((e, c) => { return throwError(e) }),
            map((response: any) => { 
              var responseobj = JSON.parse(JSON.stringify(response.body));
              responseobj.status = response.status;
              return responseobj; 
            })
            )
          
        }
        else if(method=='put'){
          return this.http.put(url,data, { headers: httpHeaderValue, observe: 'response'})
          .pipe(
            timeout(environment.AJAX_TIMEOUT),
            catchError((e, c) => { return throwError(e) }),
            map((response: any) => { 
              var responseobj = JSON.parse(JSON.stringify(response.body));
              responseobj.status = response.status;
              return responseobj; 
            })
            )
          
        }
        else if(method=='delete'){
          return this.http.delete(url, { headers: httpHeaderValue, observe: 'response'})
          .pipe(
            timeout(environment.AJAX_TIMEOUT),
            catchError((e, c) => { return throwError(e) }),
            map((response: any) => { 
              var responseobj = JSON.parse(JSON.stringify(response.body));
              responseobj.status = response.status;
              return responseobj; 
            })
            )
          
        }
        else{
          return this.http.post(url,data, { headers: httpHeaderValue, observe: 'response'})
          .pipe(
            timeout(environment.AJAX_TIMEOUT),
            catchError((e, c) => { return throwError(e) }),
            map((response: any) => { 
              var responseobj = JSON.parse(JSON.stringify(response.body));
              responseobj.status = response.status;
              return responseobj; 
            })
            )
          
        }

    }//.End of If else
       
  }// .End of ApiCall()






  


}