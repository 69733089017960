import { Component, OnInit, Input, Output, EventEmitter,HostListener } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-event-box-popup',
  templateUrl: './event-box-popup.component.html',
  styleUrls: ['./event-box-popup.component.scss'] 
})
export class EventBoxPopupComponent implements OnInit {

  public ListOfTeachers = [
    {id: 1, name: 'Teacher 1'},
    {id: 2, name: 'Teacher 2'},
    {id: 3, name: 'Teacher 3'},
    {id: 4, name: 'Teacher 4'}
  ]

  //Are you sure you want to re-generate password for the school user?
  @Input() modal_title : any ;

  //A mail will be sent at the user’s registered email address containing the newly generated password.
  @Input() modal_time : any = "" ;
  @Input() modal_date : any = "" ;
  @Input() class_full : any = "" ;
  @Input() FooterTxt :  any = "" ;
  @Input() modal_teacher_name:any='';
  @Input() modal_teacher_image:any='';
  @Input() modal_id:any='';
  @Input() modal_class_name:any='';
  @Input() modal_class_capacity:any='';
  @Input() modal_filled_seat:any='';
  @Input() modal_next_class:any='';
  @Input() modal_total_class:any='';
  @Input() modal_remain_class:any='';
  @Input() modal_day_name:any='';


  //@Input() RemoveFunction : Function;

  @Output() DoProcced = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  
  slotwiseStudentlist(){
    console.log(this.modal_id);
  }

  Proceed(){
    this.DoProcced.emit();
  }
  @HostListener('document:keyup', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
      $('.close').click();
    }
  }


}
