import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eclipse'
})
export class EclipsePipe implements PipeTransform {

  // transform(value: any, ...args: any[]): any {
  //   return null;
  // }

  transform(value: string, maximumlength: number = 30): string {
   
    if(value==undefined) return "";
    if(value.length <= maximumlength ) return value;
    else{
      return (value.substr(0,maximumlength-3)+"..." );
    }
  }

}
