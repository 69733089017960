import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AjaxService } from '../ajax.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';




@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private router: Router,
    private ajaxService : AjaxService,public navCtrl: NgxNavigationWithDataComponent, 
    private spinner: NgxSpinnerService) { }



  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : To Save the Admin user Info into Local Storage
  */
  SaveAdminUserInfoIntoLocalStorage(data : any ){

    localStorage.setItem("admin_token",data.token);
    localStorage.setItem("admin_name",((data.full_name!=undefined)?data.full_name:""));
  }


/**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : To Delete the Admin user Info from Local Storage
  */
  DeleteAdminUserInfoFromLocalStorage(){
    
    localStorage.removeItem("admin_token");
    localStorage.removeItem("admin_name");

  }//.End of DeleteAdminUserInfoFromLocalStorage()


 /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : Get Admin Info from Local Storage
  */
  GetAdminUserInfoFromLocalStorage(){
    let obj={};
    obj["admin_token"] = (localStorage.getItem("admin_token")!= null) ?
                                    localStorage.getItem("admin_token") : undefined ;
                            
    obj["admin_name"] = (localStorage.getItem("admin_name")!= null) ?
                                    localStorage.getItem("admin_name") : undefined ;  
 
    return obj;
  }//.End of GetAdminUserInfoFromLocalStorage()
  




 /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @param callback : for any callback
   @purpose : HandleErrorCode, Check error code, If it is called in all over the ajaxService function to handle error with statuscode
  */
HandleErrorCode(err : any,callback:any = null){
  let StatusCode : number = err.status;
  let ERROR_REDIRECT = environment.ERROR_REDIRECT ;
  if (err.status == 401) {
      this.spinner.hide();
      StatusCode = 401;
      this.DeleteAdminUserInfoFromLocalStorage();
      this.router.navigate(['/admin']);
  }
  else if (err.status == 404 && ERROR_REDIRECT ==true) {
    this.spinner.hide();
    this.router.navigate(['/page-not-found']);
  }
  else if (err.status == 500 && ERROR_REDIRECT ==true) {
    this.spinner.hide();
    this.router.navigate(['/internal-server-error']);
  }
  //return StatusCode;
}//.End of HandleError()




 /**
   @Date : 19-11-2019
   @Developer : DeepJyoty
   @param callback : for any callback
   @purpose : Handle 202 Success statuscode
  */
 HandleSuccessCode(result : any,callback:any = null){

  let StatusCode : number = result.status;

  if (result.status == 202) {
      StatusCode = 202;
      localStorage.setItem("admin_token",result.response.raws.data.token);
  }
  //return StatusCode;
}//.End of HandleSuccessCode()







}
