import { Directive ,  ElementRef, HostListener, Input} from '@angular/core';



@Directive({
  selector: '[appToUsername]'
})
export class ToUsernameDirective {

  specialCharsArray : any =[
   ' ', '!', '"' , '#' ,'$' ,'%' ,'&' ,'(' ,')' ,'*' ,'+' ,',' ,'/' ,':' ,';' ,'<' ,'=' ,'>' ,'?' ,'@' ,'[' ,'\\'
    ,']' ,'^' ,'`' ,'{'  ,'|'  ,'}' ,'~' 
  ]


  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event','$event.target.value']) onKeyDown(event,val) {
    let e = <KeyboardEvent> event;
    e = e || event;
     //console.log(e.keyCode);
     
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything

              var inp = String.fromCharCode(e.keyCode); 
              if(!this.specialCharsArray.includes(inp)){
                return;
              }
              else{  e.preventDefault();  }
        }

        var inp = String.fromCharCode(e.keyCode); 
        if(!this.specialCharsArray.includes(inp)){
          return;
        }
        else{ e.preventDefault();  }


        // Ensure that it is a number and stop the keypress
        // if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        //     e.preventDefault();
        // }  
  }


  RemoveSpecialChars(str : string){
    let result : string = "";
    for (var i = 0; i < str.length; i++) {
      let chr = str.charAt(i);
      if(!this.specialCharsArray.includes(chr)){
        result+= chr ;
      }
    }
    return result;
  }

}
