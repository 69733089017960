import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer-view",
  templateUrl: "./footer-view.component.html",
  styleUrls: ["./footer-view.component.scss"],
})
export class FooterViewComponent implements OnInit {
  date: number = new Date().getFullYear();

  constructor() {}

  ngOnInit() {}
}
