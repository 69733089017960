import { Directive, Input, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appImageFallback]'
})
export class ImageFallbackDirective {
  @Input() public appImageFallback: string;
  
  public errorImageUrl = '';

  BackgroundColorCode : any = "#ffffff";

  TextColorCode : any = "#3caef2";


  constructor(private el: ElementRef) { }
  
  /**
   * errorHandler
   */
  @HostListener('error')
  public errorHandler() {
     //console.log("error handle")
    if ( this.appImageFallback!=undefined && this.appImageFallback!=null && this.appImageFallback!=""){
      this.el.nativeElement.src =  this.appImageFallback ;
      return false;
    }
    try{
      let height : any =  this.el.nativeElement.getAttribute('height') ;
      let width : any =  this.el.nativeElement.getAttribute('width') ;
      let data_fontsize : any =  this.el.nativeElement.getAttribute('data-fontsize') ;
      let data_textcolorcode : any =  this.el.nativeElement.getAttribute('data-textcolorcode') ;
      let data_backgroundcolorcode : any =  this.el.nativeElement.getAttribute('data-backgroundcolorcode') ;
      let data_noimgurl : any =  this.el.nativeElement.getAttribute('data-noimgurl') ;  

      if(data_noimgurl!=undefined && data_noimgurl!=null && data_noimgurl!=""){
        this.errorImageUrl = data_noimgurl ;
        this.el.nativeElement.src =  this.errorImageUrl;
        return false;
      }



      let alt_tag : any =  this.el.nativeElement.getAttribute('alt') ;
      if(alt_tag=="" || alt_tag==undefined) return false;

      if(data_textcolorcode!=undefined && data_textcolorcode!=null && data_textcolorcode!=""){
        this.TextColorCode = data_textcolorcode ;
      }
  
      if(data_backgroundcolorcode!=undefined && data_backgroundcolorcode!=null && data_backgroundcolorcode!=""){
        this.BackgroundColorCode = data_backgroundcolorcode ;
      }
  
      var calculated_height_forText = (height/2)+(height*0.1) ;
      this.errorImageUrl = this.ConvertStringToImg(this.el.nativeElement.alt,width,height,data_fontsize,this.TextColorCode,(width/2),calculated_height_forText,600);
      this.el.nativeElement.src =  this.errorImageUrl;

    }catch(ex){
      this.errorImageUrl = "";
    }

    
       
  }





  /**
   * ngOnInit
   */
  public ngOnInit() {
  	//console.log(this.el.nativeElement.alt);
  }




  public ConvertStringToImg(str : string,canvas_width : number,canvas_height: number,font_size:number,
  color_hex_code:string,canvas_x:number,canvas_y:number,font_weight:number=500){

 // console.log('List', str);

    var canvas = document.createElement('canvas');
    canvas.width = canvas_width;
    canvas.height = canvas_height;

    var tCtx = canvas.getContext('2d');

    //tCtx.beginPath();
    tCtx.fillStyle = this.BackgroundColorCode;
    tCtx.fillStyle = "";
    tCtx.fillRect(0,0,canvas_width,canvas_height);


    tCtx.font = font_weight+" "+font_size+"px Montserrat";
    tCtx.fillStyle = color_hex_code;
    tCtx.textAlign = "center";     
    tCtx.fillText(str,canvas_x, canvas_y);
    let canvus_url = ""+tCtx.canvas.toDataURL('image/jpeg', 1.0);
    canvas.remove();
    //(<any>document.getElementById('image')).src = tCtx.canvas.toDataURL();
    return (canvus_url);
}
}