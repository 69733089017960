import { Component, OnInit, AfterViewInit } from '@angular/core';
import {FormBuilder,Validator,FormControl, FormGroup, Validators} from '@angular/forms';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonHelperService } from '../services/common-helper.service';
import { Router,ActivatedRoute } from '@angular/router';
import { AjaxService } from '../services/ajax.service';
import { SchoolAdminService } from '../services/school-admin/school-admin.service';
import {environment} from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  
  ResourceMode : string = "" ;
  youtubeid : string = "";
  youtubeembedurl : any = "";
  audiourl : any = "";
  docurl : string = "";
  document_title : string = "";
  vocabularyid : string = "";
  VocabularyDetailsData : any = {};
  content:any="";
  badge_image:any="";
  badge_name:any="";

  constructor(private spinner:NgxSpinnerService,private fb:FormBuilder,private navCtrl:NgxNavigationWithDataComponent,
    private router:Router,public commonHelperService:CommonHelperService,private ajaxService:AjaxService,
    private schooladminService:SchoolAdminService,private route: ActivatedRoute, private _sanitizer : DomSanitizer) { 
    }



  ngOnInit() {
        this.route.queryParams.subscribe((params : any) =>{
          this.ResourceMode =  params["resource"];
          if(this.ResourceMode=="audio"){  
            console.log(params["audiourl"]);

            this.audiourl =this._sanitizer.bypassSecurityTrustResourceUrl(params["audiourl"]); 
           }
          else  if(this.ResourceMode=="video"){  
            this.youtubeid =  params["youtubeid"];
            console.log(params["youtubeid"]);
            this.youtubeembedurl =  this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+ this.youtubeid);
          }
          else  if(this.ResourceMode=="document"){  
            console.log(params["docurl"]);
            this.docurl = params["docurl"];  
            this.document_title = params["document_title"];  
          }
          else  if(this.ResourceMode=="vocabulary"){  
            this.vocabularyid = params["id"];  
            this.GetVocabularyDetails();
          }
          else if(this.ResourceMode=="extra"){ 
            this.content = params["content"];  
          }
          else if(this.ResourceMode=="badge"){ 
            this.badge_image = params["badge_image"]; 
            this.badge_name = params["badge_name"]; 
          }
        })
  }






 /**
 @Date : 20-12-2019
 @Developer : Deepjyoty Roy
 @Purpose : [ Get Vocabulary Details ]
 */
GetVocabularyDetails(){
  let adminInfo = this.schooladminService.GetSchool_AdminUserInfoFromLocalStorage();
  let token : any = adminInfo['school_admin_token'];
  const form_data : any = new FormData();
  form_data.append('vocabulary_id',  this.vocabularyid);
  this.spinner.show();
  this.ajaxService.ApiCall(form_data,'/vocabulary/details','post',token)
         .subscribe(
          result=>{
              this.spinner.hide();
              this.schooladminService.HandleSuccessCode(result);
              let data = result.response.raws.data.dataset;
             
              this.VocabularyDetailsData = data;  
              this.VocabularyDetailsData.categories_names = 
              (this.VocabularyDetailsData.vocabulary_categories!="")?
              this.VocabularyDetailsData.vocabulary_categories.split(",").map(x=>""+x):[];

              this.VocabularyDetailsData.sample_lang = JSON.parse(this.VocabularyDetailsData.example);
             console.log(this.VocabularyDetailsData);
             console.log("VocabularyDetailsData.sample_lang.jp ", this.VocabularyDetailsData.sample_lang.jp);
          },
          error=>{
              this.spinner.hide();
              this.schooladminService.HandleErrorCode(error);
          }
         );
}



/**
  @Date : 20-12-2019
  @CreatedBy : Deepjyoty 
  @purpose :To Play the audio in Vocabulary Details
*/
playAudio(e:any,elementid : any){
e.preventDefault();
(<any>document.getElementById(elementid)).play();
}



}
