import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename'
})
export class FilenamePipe implements PipeTransform {

  // transform(value: any, ...args: any[]): any {
  //   return null;
  // }
  transform(value: string): string {
    if(value==undefined || value=="") return "";
    let newStr: string = value.substring(value.lastIndexOf("/")+1);
    return newStr;
  }

}
