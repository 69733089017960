import { Directive ,  ElementRef, HostListener, Input} from '@angular/core';


@Directive({
  selector: '[appToCamelCase]'
})
export class ToCamelCaseDirective {

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event','$event.target.value']) onKeyDown(event,val) {
    let e = <KeyboardEvent> event;
    e = e || event;
    console.log(e.keyCode)
     
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything

              var inp = String.fromCharCode(e.keyCode); 
              if (/[a-zA-Z'\s]/.test(inp)){
                return;
              }
              else{  e.preventDefault();  }
        }



        var inp = String.fromCharCode(e.keyCode); 
        if (/^[a-zA-Z'\s]/.test(inp)){
          return;
        }
        else{ e.preventDefault();  }


        // Ensure that it is a number and stop the keypress
        // if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        //     e.preventDefault();
        // }  
  }


  @HostListener('keyup', ['$event','$event.target.value']) onKeyUp(event,val) {
    let e = <KeyboardEvent> event;
     
    //console.log("this.el.nativeElement.value ",this.el.nativeElement.value);
    //console.log("this.ConvertToCamelCase(this.el.nativeElement.value) ",this.ConvertToCamelCase(this.el.nativeElement.value));
    this.el.nativeElement.value =  this.ConvertToCamelCase(this.el.nativeElement.value) ;
        
  }


  ConvertToCamelCase(str : string){
     if(str==undefined || str == null || str=="") return "";
     else if(str.length==1){
       return str.toUpperCase();
     }
     else{
         var words = str.split(' ');
         var createdwords : any = [];
         words.forEach(wrd=>{
            wrd = wrd.charAt(0).toUpperCase() + wrd.slice(1).toLowerCase();
            createdwords.push(wrd);
         })
         return(createdwords.join(" "))
         //return (str);
     }
  }




}
