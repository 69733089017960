import { Component, OnInit } from '@angular/core';
import { CommonHelperService } from '../services/common-helper.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { AjaxService } from '../services/ajax.service';
import { StudentService } from '../services/student/student.service';
import moment from 'moment-timezone';
import { MixpanelService } from '../services/mixpanel.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-teachercheckout',
  templateUrl: './teachercheckout.component.html',
  styleUrls: ['./teachercheckout.component.scss']
})
export class TeachercheckoutComponent implements OnInit {

  languageID : any = "";
  checkoutForm : any ;
  IsFormLoadFirstTime : boolean = true;
  rememberMe: number = 0;
  successMessage: any = "";
  errorMessage : any = "";
  plan_id : any = "GRAMMAR-PRO";
  isTermsCondition: boolean = true;
  form_detail: any = {};
  plan_detail: any = {};
  price: any = 0;
  weekly_english_pdf_price: any = 0;
  weekly_english_pdf: boolean = false;
  assessment_coaching_session_price: any = 0;
  assessment_coaching_session: boolean = false;
  agree_promotional_email:boolean = false;
  terms_condition:boolean = false;
  // agree_promotional_email: any = 0;
  // terms_condition: any = 0;

  env = environment;

  public userTz: string;

  add_on_prices = {
    "grammar-pro": {
      weekly_english_pdf_price: 7.00,
      assessment_coaching_session_price: 37.00
    },
    "lesson-bundle": {
      weekly_english_pdf_price: 7.00,
      assessment_coaching_session_price: 37.00
    },
    "oral-assessment": {
      weekly_english_pdf_price: 7.00,
      assessment_coaching_session_price: 79.00
    },
    "teaching-with-timelines": {
      weekly_english_pdf_price: 7.00,
      assessment_coaching_session_price: 37.00
    }
  }


  updateStudentFinalPriceButtonPrice(){
    let weekly = this.weekly_english_pdf?this.add_on_prices[this.plan_id.toLowerCase()].weekly_english_pdf_price:0;
    let assessment = this.assessment_coaching_session?this.add_on_prices[this.plan_id.toLowerCase()].assessment_coaching_session_price: 0
    this.price = parseInt(this.plan_detail.price)+parseInt(weekly)+parseInt(assessment);
    // console.log("updateStudentFinalPriceButtonPrice : ",this.price,this.plan_detail.price,this.weekly_english_pdf_price,this.assessment_coaching_session_price,this.plan_detail.price);

    document.getElementById('buy_now').innerHTML = "Buy Now "+this.plan_detail.currency+parseFloat(this.price).toFixed(2);
  }

  checkBoxSelectionSet(event:any){
    // console.log("checkBoxSelectionSet : ",event.target.name,event.target.checked,this[event.target.name]);
    this[event.target.name] = event.target.checked;

    if(event.target.name == 'weekly_english_pdf'){
      this[event.target.name+'_price'] = (event.target.checked)?4.00:0;
      this.updateStudentFinalPriceButtonPrice();
      // console.log("weekly_english_pdf :  ",this[event.target.name+'_price']);
    }

    if(event.target.name == 'assessment_coaching_session'){
      this[event.target.name+'_price'] = (event.target.checked)?37.00:0;
      this.updateStudentFinalPriceButtonPrice();
      // console.log("assessment_coaching_session :  ",this[event.target.name+'_price']);
    }

    if(event.target.name == 'terms_condition'){
      this.isTermsCondition = !event.target.checked;
    }

  }

  onProductChange(event:any){
    this.plan_id = event.target.value;
    this.LoadProductDetail();
    console.log("seelcted Plan",this.plan_id);
    // console.log(" event on priooduct chnage :",event.target.name,event.target.value);
  }

  test(event:any){
    this.plan_id = 'makdoom'
  }
  constructor(private http:HttpClient,private _commonHelperService : CommonHelperService,private fb: FormBuilder, public commonHelperService : CommonHelperService ,
              private spinner: NgxSpinnerService,private _student_Service : StudentService, private ajaxService: AjaxService, private route: ActivatedRoute,private router: Router, private mixpanelService: MixpanelService) {
    this.userTz = moment.tz.guess();
  }

  handler:any = null;

  // password and confirm password validation
  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  ngOnInit() {
    this.reloadData();
  }

  reloadData(){
    this.plan_id = this.route.snapshot.paramMap.get('plan_id') || "LESSON-BUNDLE";
    this.plan_id = this.plan_id.toUpperCase();
    this.checkoutForm = this.fb.group({
        fullname:['', [Validators.required]],
        email:['', [Validators.required]],
        payment_method:['stripe', [Validators.required]],
        terms_condition:['', [Validators.required]],
      }
    );
    if(environment.Enable_MixPanel)
    {
      this.mixpanelService.track("View Page Student Login");
    }
    this.loadStripe();
    this.LoadProductDetail();
  }

  async LoadProductDetail(){
    console.log('LoadProductDetail');
    let  response = await this.http.post<any>(this.env.API_URL+ '/v1/subscription/planDetails',{plan_id:this.plan_id}).toPromise();

    // return response;

    if(response.response.raws.data.status == false){
      // alert('Plan not found');
      this.router.navigate(['/internal-server-error']);
    }else{
      this.plan_detail = response.response.raws.data.dataset;
      this.price = this.plan_detail.price;
      // console.log("LoadProductDetail : ",this.price,this.plan_detail );
      this.updateStudentFinalPriceButtonPrice();
    }
  }

  loadStripe() {
    // console.log("loading initailay  Load");
    if(!window.document.getElementById('stripe-script')) {
      let s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: this.env.stripePublishkey,
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // console.log("initial Load : ",token)
            // alert('Payment Success!!');
          }
        });
      }
      window.document.body.appendChild(s);
    }
  }

  /* Password getter methord */
  get fullname(){
    return this.checkoutForm.get('fullname');
  }

  /* Password getter methord */
  get lname(){
    return this.checkoutForm.get('lname');
  }
  /* Password getter methord */
  get email(){
    return this.checkoutForm.get('email');
  }
  /* Password getter methord */
  get username(){
    return this.checkoutForm.get('username');
  }
  /* Password getter methord */
  get password(){
    return this.checkoutForm.get('password');
  }

  /* Password getter methord */
  get confirm_password(){
    return this.checkoutForm.get('confirm_password');
  }

  /* Password getter methord */
  get payment_method(){
    return this.checkoutForm.get('payment_method');
  }

  /* Password getter methord */
  // get plan_id(){
  //   console.log("plan_id : ",this.checkoutForm.get('plan_id'),this.checkoutForm.get('plan_id').value);
  //   return this.checkoutForm.get('plan_id');
  // }


  get f(){
    return this.checkoutForm.controls;
  }

  async stripePay(){

    try {
      let  res = await this.http.post<any>(this.env.API_URL+ '/v1/checkout/stripe-pay-onetime',this.form_detail).toPromise();
      // check for response
      this.spinner.hide();
      // check payment response for error
      if(res.response.raws.data.status == true){
        if(res.response.raws.data.is_downloadable == true){
          alert(res.response.raws.data.download_link)
          alert('Thanks for making payment, Please download your digital product');
          window.open(res.response.raws.data.download_link)
          // window.open(res.response.raws.data.download_link, "_blank");
          this.router.navigate(['/teachercheckout']);
        }else if(this.plan_detail.plan_user_type == 'S'){
          alert('Unable to process payment, Please try after some time!!');
          this.router.navigate(['/students']);
        }else{
          alert('Unable to process payment, Please try after some time!!');
          this.router.navigate(['/schools']);
        }
      }else{
        alert('Unable to process payment, Please try after some time!!');
      }
    } catch (err) {
      this.spinner.hide();
      this.successMessage = "";
      this.errorMessage = err.error.response.raws.error_message;
      setTimeout(()=>{
        this.errorMessage = "";
        this.successMessage = "";
      },environment.MSG_TIMEOUT);

    }
  }

  getStripToken() {
    console.log('getStripToken : ',this.plan_detail,this.form_detail);
    let handler = (<any>window).StripeCheckout.configure({
      image: 'assets/images/logo-dark.svg',
      key: this.env.STRIPE_KEY,
      locale: 'auto',
      //panelLabel: "GiveMeMoney",
      // token: function (token: any) {
      token: token =>{
        this.form_detail.stripe_token = token;
        this.form_detail.stripe_token_id = token.id;
        console.log(this.form_detail);
        this.stripePay();
      }
    });
    handler.open({
      name: this.plan_detail.plan_name,
      description: this.plan_detail.plan_description,
      amount: this.price * 100,
      email:this.form_detail.email
    });
  }

  async checkUserExist(email){
    let  res = await this.http.post<any>(this.env.API_URL+ '/v1/student/check-exist',email).toPromise();
    return res.response.raws.data.status;
  }

  /**
   @Date : 13-11-2019
   @Developer : Deepak Visani
   @Purpose : [loginUser making user login and save user info to local storage]
   */
  async checkoutUser(){
    this.IsFormLoadFirstTime = false;
    console.log('checkoutUser start',this.checkoutForm.valid);
    if(this.checkoutForm.valid){

      this.spinner.show();
      // console.log('inside valid',this.form_detail);
      let weekly1 = this.weekly_english_pdf?this.add_on_prices[this.plan_id.toLowerCase()].weekly_english_pdf_price:0;
      let assessment1 = this.assessment_coaching_session?this.add_on_prices[this.plan_id.toLowerCase()].assessment_coaching_session_price: 0
      // start :: AG :: prepare form input parameter for checkout
      this.form_detail.fullname    =  this.fullname.value;
      this.form_detail.email    =  this.email.value;
      this.form_detail.agree_promotional_email    =  this.agree_promotional_email;
      this.form_detail.payment_method    =  this.payment_method.value; // radi button paypal/stripe
      this.form_detail.terms_condition    =  this.terms_condition; // true false
      this.form_detail.price    =  parseInt(this.plan_detail.price)+parseInt(weekly1)+parseInt(assessment1);
      this.form_detail.plan_id    =  this.plan_id;

      //onrecurring  payment
      if(this.plan_detail.billing_interval == 'O'){
        console.log('onetime payment');
        // for student payment
        this.form_detail.weekly_english_pdf_price    =  this.weekly_english_pdf_price;
        this.form_detail.assessment_coaching_session_price    =  this.assessment_coaching_session_price;
      }else{
        this.form_detail.weekly_english_pdf_price    =  this.weekly_english_pdf_price;
        this.form_detail.assessment_coaching_session_price    =  this.assessment_coaching_session_price;
      }
      // stop :: AG :: prepare form input parameter for checkout
      // console.log("checkoutUser ",this.form_detail);

      //  payment integration
      //if(this.form_detail.payment_method == 'stripe' || this.form_detail.payment_method == 'paypal' ){
        //recurring time payment,  need to check user already exist, since user will be created
        if(this.plan_detail.billing_interval == 'O'){
          // console.log('this.form_detail',this.form_detail);
          switch(this.form_detail.payment_method){
            case 'stripe':
              console.log("caling stripe");
              var form = document.createElement("form");
                var plan_id = document.createElement("input"); 
                var plan_title = document.createElement("input");  
                var plan_user_type = document.createElement("input");  
                var plan_name = document.createElement("input");  
                var full_name = document.createElement("input");  

                var user_type = document.createElement("input");  
                var plan_description = document.createElement("input");  
                var fname = document.createElement("input");  
                var lname = document.createElement("input");  
                var email = document.createElement("input");  
                var price = document.createElement("input");  
                var username = document.createElement("input");  
                var weekly_english_pdf_price = document.createElement("input");  
                var assessment_coaching_session_price = document.createElement("input"); 
                


                form.method = "post";
                form.action = this.env.API_URL+"/auth/checkout";   
            
                plan_id.value=this.form_detail.plan_id;
                plan_id.name="plan_id";
                form.appendChild(plan_id);  

                full_name.value=this.fullname.value;
                full_name.name="full_name";
                form.appendChild(full_name);  
             

                plan_title.value=this.plan_detail.plan_title;
                plan_title.name="plan_title";
                form.appendChild(plan_title);  


                plan_user_type.value=this.plan_detail.plan_user_type;
                plan_user_type.name="plan_user_type";
                form.appendChild(plan_user_type);  

                plan_name.value=this.plan_detail.plan_name;
                plan_name.name="plan_name";
                form.appendChild(plan_name);  

                user_type.value=this.plan_detail.user_type;
                user_type.name="user_type";
                form.appendChild(user_type);  

                plan_description.value=this.plan_detail.plan_description;
                plan_description.name="plan_description";
                form.appendChild(plan_description);  

                fname.value=this.form_detail.fname;
                fname.name="fname";
                form.appendChild(fname);  

                lname.value=this.form_detail.lname;
                lname.name="lname";
                form.appendChild(lname);  

                email.value=this.form_detail.email;
                email.name="email";
                form.appendChild(email); 

                price.value=this.form_detail.price;
                price.name="price";
                form.appendChild(price); 

                username.value=this.form_detail.username;
                username.name="username";
                form.appendChild(username); 

                weekly_english_pdf_price.value=this.form_detail.weekly_english_pdf_price;
                weekly_english_pdf_price.name="weekly_english_pdf_price";
                form.appendChild(weekly_english_pdf_price); 

                assessment_coaching_session_price.value=this.form_detail.assessment_coaching_session_price;
                assessment_coaching_session_price.name="assessment_coaching_session_price";
                form.appendChild(assessment_coaching_session_price); 

            
                document.body.appendChild(form);
            
                form.submit();
              break;
            case 'paypal':
              break;
            default:
              // payment method not associated
              alert("1 Please contact support team to make payment");
          }
        }else{
          switch(this.form_detail.payment_method){
            case 'stripe':
              console.log("caling stripe");
              var form = document.createElement("form");
                var plan_id = document.createElement("input"); 
                var plan_title = document.createElement("input");  
                var plan_user_type = document.createElement("input");  
                var plan_name = document.createElement("input");  
                var user_type = document.createElement("input");  
                var plan_description = document.createElement("input");  
                var fname = document.createElement("input");  
                var lname = document.createElement("input");  
                var email = document.createElement("input");  
                var price = document.createElement("input");  
                var username = document.createElement("input");  
                var weekly_english_pdf_price = document.createElement("input");  
                var assessment_coaching_session_price = document.createElement("input"); 
                var full_name = document.createElement("input");  
             


                form.method = "post";
                form.action = this.env.API_URL+"/auth/checkout";   
            
                plan_id.value=this.form_detail.plan_id;
                plan_id.name="plan_id";
                form.appendChild(plan_id);  
            
                full_name.value=this.fullname.value ;
                full_name.name="full_name";
                form.appendChild(full_name);  

                plan_title.value=this.plan_detail.plan_title;
                plan_title.name="plan_title";
                form.appendChild(plan_title);  


                plan_user_type.value=this.plan_detail.plan_user_type;
                plan_user_type.name="plan_user_type";
                form.appendChild(plan_user_type);  

                plan_name.value=this.plan_detail.plan_name;
                plan_name.name="plan_name";
                form.appendChild(plan_name);  

                user_type.value=this.plan_detail.user_type;
                user_type.name="user_type";
                form.appendChild(user_type);  

                plan_description.value=this.plan_detail.plan_description;
                plan_description.name="plan_description";
                form.appendChild(plan_description);  

                fname.value=this.form_detail.fname;
                fname.name="fname";
                form.appendChild(fname);  

                lname.value=this.form_detail.lname;
                lname.name="lname";
                form.appendChild(lname);  

                email.value=this.form_detail.email;
                email.name="email";
                form.appendChild(email); 

                price.value=this.form_detail.price;
                price.name="price";
                form.appendChild(price); 

                username.value=this.form_detail.username;
                username.name="username";
                form.appendChild(username); 

                weekly_english_pdf_price.value=this.form_detail.weekly_english_pdf_price;
                weekly_english_pdf_price.name="weekly_english_pdf_price";
                form.appendChild(weekly_english_pdf_price); 

                assessment_coaching_session_price.value=this.form_detail.assessment_coaching_session_price;
                assessment_coaching_session_price.name="assessment_coaching_session_price";
                form.appendChild(assessment_coaching_session_price); 

            
                document.body.appendChild(form);
            
                form.submit();
              break;
            case 'paypal':
              break;
            default:
              // payment method not associated
              alert("1 Please contact support team to make payment");
          }
        }

      /*}else{
        // payment method not associated
        alert("2 Please contact support team to make payment");
      }*/



      //after payment, just allow user to download digital bundle products
      if(this.plan_detail.billing_interval == 'O'){

      }else{
        // recurring payment, monthly or yearly, User account need to create after payment successfull

      }

    }//.End if(this.checkoutForm.valid)
    else{
      console.log("form error 1111");
    }
  }//.End loginUser()

  planIdChangedHandler(ev){
    this.plan_id = ev;
    this.reloadData();
  }
}
