import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';


@Directive({
  selector: '[appInsufficientitems]'
})
export class InsufficientitemsDirective {

  @Input() existingItems: number;
  @Output() loadMoreItems: EventEmitter<void> = new EventEmitter();

  previousExistingItems: number;

  constructor(private element: ElementRef) {
  }

  ngOnChanges(): void {
    const htmlElement = this.element.nativeElement as HTMLElement;
    if (this.existingItems && this.existingItems !== this.previousExistingItems && htmlElement.clientHeight === htmlElement.scrollHeight) {
      this.previousExistingItems = this.existingItems;
      this.loadMoreItems.emit();
    }
  }

}
