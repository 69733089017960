import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { SchoolAdminService } from '../services/school-admin/school-admin.service';
import { StudentService } from '../services/student/student.service';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {

  public isAdminToken: any = '';
  public isSchoolAdminToken: any = '';
  public isStudentToken: any = '';

  public specificRoleUrl : any = '';

  constructor(
    public _adminService : AdminService,
    public _schoolAdminService : SchoolAdminService,
    public _student:StudentService
  ) { }

  ngOnInit() {
    this.isAdminToken = this._adminService.GetAdminUserInfoFromLocalStorage();
    this.isSchoolAdminToken = this._schoolAdminService.GetSchool_AdminUserInfoFromLocalStorage();
    this.isStudentToken = this._student.GetStudentUserInfoFromLocalStorage();


    if(this.isAdminToken.admin_token !== undefined && this.isAdminToken.admin_token !== null && this.isAdminToken.admin_token !== ''){
      this.specificRoleUrl = '/admin/dashboard';
    }else if(this.isSchoolAdminToken.school_admin_token !== undefined && this.isSchoolAdminToken.school_admin_token !== null && this.isSchoolAdminToken.school_admin_token !== ''){
      this.specificRoleUrl = '/schools/dashboard';
    }else if(this.isStudentToken.student_token !== undefined && this.isStudentToken.student_token !== null && this.isStudentToken.student_token !== ''){
      this.specificRoleUrl = '/students/dashboard';
    }
  }

}
