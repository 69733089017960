import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CommonHelperService } from 'src/app/services/common-helper.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-storybook-webview',
  templateUrl: './storybook-webview.component.html',
  styleUrls: ['./storybook-webview.component.scss']
})
export class StorybookWebviewComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, public commonHelperService: CommonHelperService, private ajaxService: AjaxService, private _admin_service: AdminService) { }

  TotalCount: any = 0;
  noOfStorybooks: any = 40;
  pageNo: any = 1;
  loadtab:boolean = false;

  listOfCategory: any = [];
  listOfSubCategory: any = [];
  listOfallStorybooks: any = [];
  currentCategoryId:any='';
  currentSubCategoryId:any='';

  ngOnInit() {
    this.getAllstorybooks(1,true);
    this.parentCategoryList();
  }

  // function to get tab respective storybooks
  getAllstorybooks(pageNo, IsPageLoadFirst = true) {
    // this.listOfallStorybooks = [{
    //   "public_identifier":"ppryy10gjuyy",
    //   "story_img":"https://pocketpassport-new-development.s3-us-west-2.amazonaws.com/assets/vocabularies/1/158288912783045.png",
    //   "story_name":"Story 1",
    //   "school_name":"Msspl School",
    //   "page_count":12,
    //   "parent_category":"Science",
    //   "sub_category":"Science Fiction"
    // },{
    //   "public_identifier":"ppryy10gjuyy",
    //   "story_img":"https://pocketpassport-new-development.s3-us-west-2.amazonaws.com/assets/vocabularies/9/158288913382007.png",
    //   "story_name":"Story 2",
    //   "school_name":"Msspl School2",
    //   "page_count":13,
    //   "parent_category":"Science1",
    //   "sub_category":"Science Fiction1"
    // },{
    //   "public_identifier":"ppryy10gjuyy",
    //   "story_img":"hhttps://pocketpassport-new-development.s3-us-west-2.amazonaws.com/assets/vocabularies/5/158288913085751.png",
    //   "story_name":"Story 3",
    //   "school_name":"Msspl School3",
    //   "page_count":15,
    //   "parent_category":"Science2",
    //   "sub_category":"Science Fiction2"
    // },{
    //   "public_identifier":"ppryy10gjuyy",
    //   "story_img":"https://pocketpassport-new-development.s3-us-west-2.amazonaws.com/assets/vocabularies/1/158288912783045.png",
    //   "story_name":"Story 4",
    //   "school_name":"Msspl School4",
    //   "page_count":32,
    //   "parent_category":"Science5",
    //   "sub_category":"Science Fiction5"
    // },{
    //   "public_identifier":"ppryy10gjuyy",
    //   "story_img":"https://pocketpassport-new-development.s3-us-west-2.amazonaws.com/assets/vocabularies/1/158288912783045.png",
    //   "story_name":"Story 5",
    //   "school_name":"Msspl School5",
    //   "page_count":72,
    //   "parent_category":"Science7",
    //   "sub_category":"Science Fiction7"
    // }];
    this.spinner.show();
    if (IsPageLoadFirst) {
      this.pageNo = 1;
      pageNo = 1;
      this.listOfallStorybooks = [];
    }
    const form_data: any = new FormData();
    form_data.append('parent_id', this.currentCategoryId);
    form_data.append('subcategory_id', this.currentSubCategoryId);
    form_data.append('page_number', pageNo);
    form_data.append('no_of_records', this.noOfStorybooks);
    let adminInfo = this._admin_service.GetAdminUserInfoFromLocalStorage();
    let token: any = adminInfo['admin_token'];
    this.ajaxService.ApiCallWithLocalization(form_data, '/story-web/allPublicStorybooks', 'post', token)
      .subscribe(
        result => {
          if (IsPageLoadFirst) { this.spinner.hide(); }
          this._admin_service.HandleSuccessCode(result);
          var list = result.response.raws.data.dataset;
          if (IsPageLoadFirst) {
            this.TotalCount = result.response.raws.data.total_count;
            this.listOfallStorybooks = list;
          }
          else {
            this.listOfallStorybooks.push(...list);
          }
          this.loadtab = true;
        },
        err => {
          if (IsPageLoadFirst) { this.spinner.hide(); }
          this._admin_service.HandleErrorCode(err);
        }
      );
  }

  // function to scroll all story books
  onScroll() {
    if (this.listOfallStorybooks.length < this.TotalCount) {
      this.pageNo = this.pageNo + 1;
      this.getAllstorybooks(this.pageNo, false);
    }
  }

  // function to list parent categories
  parentCategoryList() {
    // this.listOfCategory = [{
    //   "id":"1",
    //   "name":"Cat 1"
    // },{
    //   "id":"2",
    //   "name":"Cat 2"
    // },{
    //   "id":"3",
    //   "name":"Cat 3"
    // },{
    //   "id":"4",
    //   "name":"Cat 4"
    // }];
    let adminInfo = this._admin_service.GetAdminUserInfoFromLocalStorage();
    let token: any = adminInfo["admin_token"];
    this.ajaxService.ApiCall('', "/story-web/parentCategoryList", "post", token).subscribe(
        data => {
          this.spinner.hide();
          this._admin_service.HandleSuccessCode(data);
          this.listOfCategory = data.response.raws.data.dataset;
        },
        error => {
          this.spinner.hide();
          this._admin_service.HandleErrorCode(error);
        }
      );
  }

  // function to list parent categories
  subCategoryList() {    
    // this.listOfSubCategory = [{
    //   "id":"1",
    //   "name":"Sub Cat 1"
    // },{
    //   "id":"2",
    //   "name":"Sub Cat 2"
    // },{
    //   "id":"3",
    //   "name":"Sub Cat 3"
    // },{
    //   "id":"4",
    //   "name":"Sub Cat 4"
    // }];
    this.listOfSubCategory=[];
    this.currentSubCategoryId='';
    let adminInfo = this._admin_service.GetAdminUserInfoFromLocalStorage();
    let token: any = adminInfo["admin_token"];

    const form_data: any = new FormData();
    form_data.append('parent_id', this.currentCategoryId);

    this.ajaxService.ApiCall(form_data, "/story-web/subCategoryList", "post", token).subscribe(
        data => {
          this.spinner.hide();
          this._admin_service.HandleSuccessCode(data);
          this.listOfSubCategory = data.response.raws.data.dataset;
          this.getAllstorybooks(1,true);
        },
        error => {
          this.spinner.hide();
          this._admin_service.HandleErrorCode(error);
        }
      );
  }

}
