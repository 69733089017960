import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-school-admin-popup-success-error-msg',
  templateUrl: './school-admin-popup-success-error-msg.component.html',
  styleUrls: ['./school-admin-popup-success-error-msg.component.scss']
})
export class SchoolAdminPopupSuccessErrorMsgComponent implements OnInit {

    @Input() popup_successMessage: string;
    @Input() popup_errorMessage: string;
    
    constructor() { }
  
    ngOnInit() {
    }
  
    CloseSuccessErrorMsg(event : any){
      event.preventDefault();
      this.popup_successMessage = "";
      this.popup_errorMessage = "";
    }
}
