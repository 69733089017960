import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PreviewComponent } from "./preview/preview.component";
import { ServerErrorComponent } from "./server-error/server-error.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UpgradeComponent } from "./upgrade/upgrade.component";

import { StorybookWebviewComponent } from "./storybook-webview/storybook-webview.component";
import { StorybookWebviewDetailComponent } from "./storybook-webview-detail/storybook-webview-detail.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { StudentquizComponent } from "./studentquiz/studentquiz.component";
import { StudentcheckoutComponent } from "./studentcheckout/studentcheckout.component";
import { TeachercheckoutComponent } from "./teachercheckout/teachercheckout.component";

const routes: Routes = [
  { path: "preview", component: PreviewComponent },
  { path: "internal-server-error", component: ServerErrorComponent },
  //{ path: '500', component: ServerErrorComponent},
  { path: "page-not-found", component: PageNotFoundComponent },
  { path: "upgrade", component: UpgradeComponent },
  { path: "checkout/:plan_id", component: CheckoutComponent },
  { path: "studentquiz", component: StudentquizComponent },
  { path: "studentcheckout", component: StudentcheckoutComponent },
  { path: "studentcheckout/:plan_id", component: StudentcheckoutComponent },
  { path: "teachercheckout", component: TeachercheckoutComponent },

  { path: "teachercheckout/:plan_id", component: TeachercheckoutComponent },
  //{path: '404', component: PageNotFoundComponent},
  { path: "storybook", component: StorybookWebviewComponent },
  { path: "storybook/detail/:id", component: StorybookWebviewDetailComponent },
  {
    path: "schools",
    loadChildren: () =>
      import("./school-module/school-module.module").then(
        (m) => m.SchoolModuleModule
      ),
  },
  { path: "", redirectTo: "schools", pathMatch: "full" },
  {
    path: "students",
    loadChildren: () =>
      import("./student-module/student-module.module").then(
        (m) => m.StudentModuleModule
      ),
  },
  // { path: 'free', loadChildren: () => import('./freemium/freemium.module').then(m => m.FreemiumModuleModule) },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin-module/admin-module.module").then(
        (m) => m.AdminModuleModule
      ),
  },
  {
    path: "shared",
    loadChildren: () =>
      import("./shared/shared.module").then((m) => m.SharedModule),
  },
  { path: "**", redirectTo: "schools" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
