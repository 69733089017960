import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AjaxService } from "../ajax.service";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";

@Injectable({
  providedIn: "root"
})
export class StudentService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ajaxService: AjaxService,
    public navCtrl: NgxNavigationWithDataComponent,
    private spinner: NgxSpinnerService
  ) {}

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : To Save the Student user Info into Local Storage
  */
  SaveStudentUserInfoIntoLocalStorage(data: any) {
    localStorage.setItem("student_token", data.token);
    localStorage.setItem(
      "student_name",
      data.full_name != undefined ? data.full_name : ""
    );
    localStorage.setItem(
      "school_name",
      data.school_name != undefined ? data.school_name : ""
    );
    localStorage.setItem(
      "school_admin_school_logo",
      data.school_logo != undefined ? data.school_logo : ""
    );
    localStorage.setItem(
      "plan_type",
      data.plan_type != undefined ? data.plan_type : "F"
    );
    localStorage.setItem("userTimezone", data.timezone);
    localStorage.setItem("notifications", data.new_notification_count);
  } //.End of SaveStudentUserInfoIntoLocalStorage()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : To Delete the Student user Info from Local Storage
  */
  DeleteStudentUserInfoFromLocalStorage() {
    localStorage.removeItem("student_token");
    localStorage.removeItem("student_name");
    localStorage.removeItem("school_admin_school_logo");
    localStorage.removeItem("school_name");
    localStorage.removeItem("plan_type");
    localStorage.removeItem("userTimezone");
    localStorage.removeItem("printVocabs");
  } //.End of DeleteStudentUserInfoFromLocalStorage()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @purpose : Get Student Info from Local Storage
  */
  GetStudentUserInfoFromLocalStorage() {
    let obj = {};
    obj["student_token"] =
      localStorage.getItem("student_token") != null
        ? localStorage.getItem("student_token")
        : undefined;

    obj["student_name"] =
      localStorage.getItem("student_name") != null
        ? localStorage.getItem("student_name")
        : undefined;
    obj["school_admin_school_logo"] =
      localStorage.getItem("school_admin_school_logo") != null
        ? localStorage.getItem("school_admin_school_logo")
        : undefined;
    obj["school_name"] =
      localStorage.getItem("school_name") != null
        ? localStorage.getItem("school_name")
        : undefined;
    obj["plan_type"] =
          localStorage.getItem("plan_type") != null
            ? localStorage.getItem("plan_type")
            : undefined;

    return obj;
  } //.End of GetStudentUserInfoFromLocalStorage()

  /**
   @Date : 11-11-2019
   @Developer : DeepJyoty
   @param callback : for any callback
   @purpose : To Regenrate the token when token expires
  */
  ReGenerateToken(callback: any = null) {
    var param = {};

    let AdminInfo: any = this.GetStudentUserInfoFromLocalStorage();

    param["user_id"] = AdminInfo.student_userid;
    param["login_type"] = "student";

    this.ajaxService
      .ApiCall(param, "UserMasters/regenerateToken", "post")
      .subscribe(
        result => {
          localStorage.setItem(
            "student_token",
            result.response.raws.data.token
          );
          if (callback != null) {
            callback();
          }
        },
        error => {
          if (error.status == 403) {
            this.DeleteStudentUserInfoFromLocalStorage();
            this.router.navigate(["/students"]);
          }
        }
      );
  } //.End of ReGenerateToken()

  /**
    @Date : 11-11-2019
    @Developer : DeepJyoty
    @param callback : for any callback
    @purpose : HandleErrorCode, Check error code, If it is called in all over the ajaxService function to handle error with statuscode
  */
  HandleErrorCode(err: any, callback: any = null) {
    let StatusCode: number = err.status;
    if (err.status == 401) {
      StatusCode = 401;
      this.DeleteStudentUserInfoFromLocalStorage();
      this.router.navigate(["/students"]);
    }
    //return StatusCode;
  } //.End of HandleError()

  /**
    @Date : 19-11-2019
    @Developer : DeepJyoty
    @param callback : for any callback
    @purpose : Handle 202 Success statuscode
  */
  HandleSuccessCode(result: any, callback: any = null) {
    let StatusCode: number = result.status;
    if (result.status == 202) {
      StatusCode = 202;
      localStorage.setItem("student_token", result.response.raws.data.token);
    }
    //return StatusCode;
  } //.End of HandleSuccessCode()
}
