import { Component, OnInit, Input, Output, EventEmitter,HostListener } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-confirm-box-popup',
  templateUrl: './confirm-box-popup.component.html',
  styleUrls: ['./confirm-box-popup.component.scss'] 
})
export class ConfirmBoxPopupComponent implements OnInit {

  //Are you sure you want to re-generate password for the school user?
  @Input() QuestionTxt : any ;

  //A mail will be sent at the user’s registered email address containing the newly generated password.
  @Input() FooterTxt : any = "" ;
  @Input() okbutton : any = "" ;
  //@Input() RemoveFunction : Function;

  @Input() NoButton : any = "" ;
  @Input() YesButton : any = "" ;
  @Output() DoProcced = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }


  Proceed(){
    this.DoProcced.emit();
  }
@HostListener('document:keyup', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
        $('.close').click();
    }
}


}
