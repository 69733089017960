import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { SchoolAdminService } from '../services/school-admin/school-admin.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {

  public isAdminToken: any = '';
  public isSchoolAdminToken: any = '';

  public specificRoleUrl : any = '';

  constructor(
    public _adminService : AdminService,
    public _schoolAdminService : SchoolAdminService
  ) { }

  ngOnInit() {
    this.isAdminToken = this._adminService.GetAdminUserInfoFromLocalStorage();
    this.isSchoolAdminToken = this._schoolAdminService.GetSchool_AdminUserInfoFromLocalStorage();

    if(this.isAdminToken.admin_token !== undefined && this.isAdminToken.admin_token !== null && this.isAdminToken.admin_token !== ''){
      this.specificRoleUrl = '/admin/dashboard';
      console.log(this.specificRoleUrl);
    }else if(this.isSchoolAdminToken.school_admin_token !== undefined && this.isSchoolAdminToken.school_admin_token !== null && this.isSchoolAdminToken.school_admin_token !== ''){
      this.specificRoleUrl = '/schools/dashboard';
      console.log(this.specificRoleUrl);
    }
  }

}
