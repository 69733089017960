import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-school-admin-success-error-msg',
  templateUrl: './school-admin-success-error-msg.component.html',
  styleUrls: ['./school-admin-success-error-msg.component.scss']
})
export class SchoolAdminSuccessErrorMsgComponent implements OnInit {

    @Input() successMessage: string;
    @Input() errorMessage: string;
    
    constructor() { }
  
    ngOnInit() {
    }
  
    CloseSuccessErrorMsg(event : any){
      event.preventDefault();
      this.successMessage = "";
      this.errorMessage = "";
    }
  

}
