import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedRoutingModule } from "./shared-routing.module";
import { SharedComponent } from "./shared.component";

import { DatePipe } from "@angular/common";
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { NgOtpInputModule } from "ng-otp-input";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgSelectModule } from "@ng-select/ng-select";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { ColorPickerModule } from "ngx-color-picker";
import { SortablejsModule } from "ngx-sortablejs";
import { DndModule } from "ngx-drag-drop";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { DeviceDetectorModule } from "ngx-device-detector";

import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { FullCalendarModule } from "@fullcalendar/angular"; // for FullCalendar!

import { ImgCropPopupComponent } from "../shared/components/img-crop-popup/img-crop-popup.component";
import { VocabularyItemShowComponent } from "../shared/components/vocabulary-item-show/vocabulary-item-show.component";
import { PageNotFoundComponent } from "../page-not-found/page-not-found.component";
import { ServerErrorComponent } from "../server-error/server-error.component";
import { PreviewComponent } from "../preview/preview.component";
import { VocabularyCardViewComponent } from "../shared/components/vocabulary-card-view/vocabulary-card-view.component";

import { FilenamePipe } from "../pipes/filename.pipe";
import { EclipsePipe } from "../pipes/eclipse.pipe";
import { ConfirmBoxPopupComponent } from "../shared/components/confirm-box-popup/confirm-box-popup.component";
import { EventBoxPopupComponent } from "../shared/components/event-box-popup/event-box-popup.component";
import { SafePipe } from "../pipes/safe.pipe";
import { PasswordStrengthBar } from "../shared/components/passwordStrengthBar/passwordStrengthBar";
import { MultilinePipe } from "../pipes/multiline.pipe";
import { ToCamelCaseDirective } from "../directives/to-camel-case.directive";
import { ToTitleCaseNameDirective } from "../directives/to-title-case-name.directive";
import { ToUsernameDirective } from "../directives/to-username.directive";
import { CharecterLimitDirective } from "../directives/charecter-limit.directive";
import { ImageFallbackDirective } from "../directives/image-fallback.directive";
import { ShortnamePipe } from "../pipes/shortname.pipe";
import { ToLowerCaseNameDirective } from "../directives/to-lower-case-name.directive";
import { ReplacelinkoggPipe } from "../pipes/replacelinkogg.pipe";
import { NumberOnlyDirective } from "../directives/number-only.directive";
import {} from "@angular/cdk/keycodes";
import { UppercaseDirective } from "../directives/uppercase.directive";
import { InsufficientitemsDirective } from "../directives/insufficientitems.directive";
import { EnvPipe } from "../pipes/env.pipe";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "../services/messaging.service";
import { environment } from "../../environments/environment";
import { UpgradeComponent } from "../upgrade/upgrade.component";
import { SchoolAdminSuccessErrorMsgComponent } from "../school/school-admin/common/school-admin-success-error-msg/school-admin-success-error-msg.component";
import { SchoolAdminPopupSuccessErrorMsgComponent } from "../school/school-admin/common/school-admin-popup-success-error-msg/school-admin-popup-success-error-msg.component";
import { AdminPopupSuccessErrorMsgComponent } from "../admin/common/admin-popup-success-error-msg/admin-popup-success-error-msg.component";
import { AdminSuccessErrorMsgComponent } from "../admin/common/admin-success-error-msg/admin-success-error-msg.component";
import { NgxStarsModule } from "ngx-stars";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SanitizerUrlPipe } from "../pipes/sanitizer-url.pipe";
import { CheckoutComponent } from "../checkout/checkout.component";
import { FooterViewComponent } from "./components/footer-view/footer-view.component";

@NgModule({
  declarations: [
    SharedComponent,
    ImgCropPopupComponent,
    VocabularyItemShowComponent,
    VocabularyCardViewComponent,
    PageNotFoundComponent,
    FilenamePipe,
    EclipsePipe,
    PreviewComponent,
    ServerErrorComponent,
    ConfirmBoxPopupComponent,
    EventBoxPopupComponent,
    SafePipe,
    PasswordStrengthBar,
    MultilinePipe,
    ToCamelCaseDirective,
    ToTitleCaseNameDirective,
    ToUsernameDirective,
    CharecterLimitDirective,
    ImageFallbackDirective,
    ShortnamePipe,
    ToLowerCaseNameDirective,
    ReplacelinkoggPipe,
    NumberOnlyDirective,
    UppercaseDirective,
    InsufficientitemsDirective,
    EnvPipe,
    UpgradeComponent,
    CheckoutComponent,
    SchoolAdminSuccessErrorMsgComponent,
    SchoolAdminPopupSuccessErrorMsgComponent,
    AdminPopupSuccessErrorMsgComponent,
    AdminSuccessErrorMsgComponent,
    SanitizerUrlPipe,
    FooterViewComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgxUsefulSwiperModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    NgSelectModule,
    TabsModule.forRoot(),
    ImageCropperModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxDocViewerModule,
    ColorPickerModule,
    SortablejsModule.forRoot({ animation: 150 }),
    DndModule,
    TranslateModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    AmazingTimePickerModule,
    FullCalendarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    DeviceDetectorModule,
    AccordionModule.forRoot(),
    NgxStarsModule,
    NgxExtendedPdfViewerModule,
    DragDropModule,
    // FooterViewComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImgCropPopupComponent,
    VocabularyItemShowComponent,
    VocabularyCardViewComponent,
    PageNotFoundComponent,
    FilenamePipe,
    EclipsePipe,
    PreviewComponent,
    ServerErrorComponent,
    ConfirmBoxPopupComponent,
    EventBoxPopupComponent,
    SafePipe,
    PasswordStrengthBar,
    MultilinePipe,
    ToCamelCaseDirective,
    ToTitleCaseNameDirective,
    ToUsernameDirective,
    CharecterLimitDirective,
    ImageFallbackDirective,
    ShortnamePipe,
    ToLowerCaseNameDirective,
    ReplacelinkoggPipe,
    NumberOnlyDirective,
    UppercaseDirective,
    InsufficientitemsDirective,
    EnvPipe,
    UpgradeComponent,
    CheckoutComponent,
    TranslateModule,
    NgxUsefulSwiperModule,
    TabsModule,
    InfiniteScrollModule,
    BsDatepickerModule,
    FroalaEditorModule,
    FroalaViewModule,
    SortablejsModule,
    DndModule,
    ColorPickerModule,
    FullCalendarModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    ImageCropperModule,
    TooltipModule,
    NgxDocViewerModule,
    AmazingTimePickerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgSelectModule,
    AngularFireModule,
    SchoolAdminSuccessErrorMsgComponent,
    SchoolAdminPopupSuccessErrorMsgComponent,
    AdminPopupSuccessErrorMsgComponent,
    AdminSuccessErrorMsgComponent,
    AccordionModule,
    NgxStarsModule,
    NgxExtendedPdfViewerModule,
    DragDropModule,
    SanitizerUrlPipe,
    FooterViewComponent,
  ],
  providers: [NgxNavigationWithDataComponent, DatePipe, MessagingService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
